app.factory('HomePopupService', HomePopupService);

HomePopupService.$inject = [ '$resource', 'CommonCallback', 'config' ];

function HomePopupService($resource, CommonCallback, config) {
	
	var resource = $resource('', {}, {
			'get': { 
	        	method: 'GET',
	        	isArray: false,
	        	url: config.apiUrl+'/homeAlert'
	        }
	    });
		
		function get( successCallBack) {
			resource.get({}, 
			CommonCallback.onSuccess(successCallBack), 
			CommonCallback.onError);
		}
		
		
	    return {
	    	get: get
	    };
}
