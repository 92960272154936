app.factory('AlertModalService', AlertModalService);

    AlertModalService.$inject = ['$uibModal'];

    function AlertModalService ($modal) {
    	
    	function success(text, thenCallback) {
    		open('success', text, thenCallback);
    	}
    	
    	function danger(text, thenCallback) {
    		open('danger', text, thenCallback);
    	}

        function open(mode, text, thenCallback) {
        	$modal.open({
				templateUrl : 'app/modal/alert.modal.html',
				controller : 'AlertModalController',
				controllerAs : 'vm',
				backdrop: 'static',
				keyboard: false,
				resolve: {
					config: function() {
						return {
							mode: mode,
							text: text
						};
					}
				}
			}).result.then(function() {
				if( thenCallback ) {
					thenCallback();
				}
            });
        }
        
        return {
        	success: success,
        	danger: danger
        };
}
