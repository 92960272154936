angular
  .module("app")
  .controller("UploadSlipModalController", UploadSlipModalController);

UploadSlipModalController.$inject = [
  "config",
  "$uibModalInstance",
  "DateTimepickerFactory",
  "Constant",
];

function UploadSlipModalController(
  config,
  $modalInstance,
  DateTimepickerFactory,
  Constant
) {
  var vm = this;

  //UI Variable
  vm.hour = [];
  vm.minute = [];
  vm.slip = {};
  vm.banks = [];

  //UI Function
  vm.init = init();
  vm.upload = upload;
  vm.cancel = cancel;
  vm.selectImg = selectImg;

  function init() {
    Constant.banks(function (banks) {
      vm.slip.amount = config.amount;
      vm.selectMin = "00";
      vm.selectHour = "00";
      vm.slip.date = new Date();
      vm.banks = banks;
      setHour();
      setMinute();
      DateTimepickerFactory.initialDatePicker(vm);
    });
  }

  function setHour() {
    for (var i = 0; i <= 23; i++) {
      vm.hour.push(i < 10 ? "0" + i : i);
    }
  }

  function setMinute() {
    for (var i = 0; i <= 59; i++) {
      vm.minute.push(i < 10 ? "0" + i : i);
    }
  }

  function upload() {
    if (vm.uploadSlipForm.$invalid) return;

    vm.slip.date.setHours(vm.selectHour, vm.selectMin, 0, 0);
    $modalInstance.close(vm.slip);
  }

  function cancel() {
    $modalInstance.dismiss("");
  }

  function selectImg() {}
}
