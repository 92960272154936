angular.module("app").controller("AddToCartModalController", AddToCartModalController);

AddToCartModalController.$inject = ['config', '$uibModalInstance', '$state'];

function AddToCartModalController(config, $modalInstance, $state) {
    var vm = this;

    vm.name = config.name;
    vm.img = config.img;
    vm.qty = config.qty;
    vm.unit = config.unit;
    vm.normalPrice = config.normalPrice;
    vm.memberPrice = config.memberPrice;

    vm.close = close;
    vm.cart = cart;

    function close() {
        $modalInstance.close();
    }
    
    function cart() {
        $modalInstance.close();
        $state.go('app.cart');
    }
}