(function () {
  angular.module("app").controller("LoginController", LoginController);

  LoginController.$inject = [
    "$uibModalInstance",
    "$state",
    "UserService",
    "AlertModalService",
    "$sessionStorage",
    "focus",
    "CartService",
    "ProductGroupService",
    "$cookies",
    "blockUI",
  ];

  function LoginController(
    $modalInstance,
    $state,
    UserService,
    AlertModalService,
    $sessionStorage,
    focus,
    CartService,
    ProductGroupService,
    $cookies,
    blockUI
  ) {
    var vm = this;
    var session = $sessionStorage;

    //UI variable
    vm.user = {
      email: "",
      password: "",
    };
    vm.responseMsg = null;

    //UI function
    vm.init = init();
    vm.close = close;
    vm.goRegister = goRegister;
    vm.setDrity = setDrity;
    vm.submit = submit;
    vm.cancel = cancel;

    function init() {
      focus("email");
    }

    function cancel() {
      $modalInstance.dismiss("");
    }

    function close() {
      $modalInstance.close();
    }

    function goRegister() {
      cancel();
      $state.go("app.register");
    }

    function setDrity() {
      var form = vm.userForm;
      if (form.$invalid) {
        angular.forEach(form.$error, function (controls, errorName) {
          angular.forEach(controls, function (control) {
            control.$setDirty();
          });
        });
      }
    }

    function submit() {
      if (
        vm.user.email != "" &&
        vm.user.email != undefined &&
        vm.user.password != "" &&
        vm.user.password != undefined
      ) {
        blockUI.start();

        UserService.login(vm.user, function (response) {
          if (response.responseCode == 0) {
            //success

            session.user = response.data;
            if (vm.user.remember) {
              $cookies.put("YSC_USER", JSON.stringify(session.user));
            }

            // console.log(session.user);

            ProductGroupService.getProductGroups(function (response) {
              //Inquiry Product Groups
              session.productGroups = response.data;
              CartService.get(function (response) {
                var cart = {};
                cart.products = response.data.products;
                cart.summary = response.data.summary;
                session.cart = cart;
                if (vm.user.remember) {
                  $cookies.put("YSC_CART", JSON.stringify(session.cart));
                }

                blockUI.stop();

                close();
              });
            });
          } else {
            blockUI.stop();
            vm.responseMsg = response.responseMsg;
          }
        });
      }
    }
  }
})();
