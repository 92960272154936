angular.module("app").controller("ChangePasswordController", ChangePasswordController);

ChangePasswordController.$inject = ['$sessionStorage', '$translate', 'UserService', 'ModalFactory', 'blockUI'];

function ChangePasswordController($sessionStorage, $translate, UserService, ModalFactory, blockUI) {
    var vm = this;
    var session = $sessionStorage;

    //UI variable
    vm.user = {};
    vm.changePass = {
        _id: "",
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        updateDateTime: new Date()
    }
    vm.userMenu = 'information';
    /* Nav Bar Setup */
    vm.navs = [];
    vm.isMacth = true;
    vm.responseMsg = null;

    /*UI function*/
    vm.int = init();
    vm.setDrity = setDrity;
    vm.submitForm = submitForm;

    function init() {
    	blockUI.start();
        UserService.isUserLogon(function(isUserLogon){
    		if( !isUserLogon ) return;
    	});
        
        $translate('menu.userInformation').then(function (data1) {
            $translate('changePassword').then(function (data2) {
                vm.navs[0] = { description: data1, href: "/ecommerce/information" }
                vm.navs[1] = { description: data2 }
            });
        });
        getUserLogin();
        vm.changePass._id = vm.user._id;
        blockUI.stop();
    }

    function getUserLogin() {
        vm.user = session.user;
    }

    function setDrity() {
        var form = vm.userForm;
        if (form.$invalid) {
            angular.forEach(form.$error, function (controls, errorName) {
                angular.forEach(controls, function (control) {
                    control.$setDirty();
                });
            });
        }
    }

    function submitForm() {
    	
        if (vm.changePass.newPassword != vm.changePass.confirmPassword) {
            vm.isMacth = false;
            return;
        }
        
        if (vm.changePass.oldPassword == vm.changePass.newPassword) {

        }
        
        blockUI.start();
        
        // console.log(vm.changePass);
        UserService.changePassword(vm.changePass, function (response) {
            blockUI.stop();
            if (response.responseCode == 0) {
            	
                $translate('success.user.changePassword.success').then(function (data) {
                    var config = {
                        header: data
                    }
                    ModalFactory.success(config, function () {
                        window.history.back();
                    })
                })
            } else {
                $translate(response.responseMsg).then(function (data) {
                    vm.responseMsg = data;
                });
            }
        })
    }
}