angular.module("app").controller("ForgotPasswordController", ForgotPasswordController);

ForgotPasswordController.$inject = ['$sessionStorage', '$translate', 'UserService', 'blockUI'];

function ForgotPasswordController($sessionStorage, $translate, UserService,blockUI) {
    var vm = this;

    //UI Variable
    vm.navs = [];
    vm.email = "";
    vm.responseType = "";
    vm.responseMsg = null;

    //UI function
    vm.init = init();
    vm.send = send;

    function init(){
    	
    	
        $translate('menu.forgotPassword').then(function (data1) {
                vm.navs[0] = { description: data1 }
        });
    }

    function send(){
    	if(vm.captcha2){
    		blockUI.start('Please wait...');
            UserService.reqResetPassword(vm.email ,function(res){
            	blockUI.stop();
                if(res.responseCode == 13){
                    vm.responseType = 'danger';
                    vm.responseMsg = res.responseMsg;
                }else if(res.responseCode == 0){
                    vm.responseType = 'success';
                    vm.responseMsg = 'ระบบได้จัดส่งลิงค์สำหรับเปลี่ยนรหัสผ่านไปยังอีเมล์ของท่านเรียบร้อยแล้ว';
                }
            })
    	}
    }
}