angular.module("app").controller("ItemController", ItemController);

ItemController.$inject = ["config", "$sessionStorage", "$state"];

function ItemController(config, $sessionStorage, $state) {
  var vm = this;
  var session = $sessionStorage;

  /*UI variable*/
  vm.config = config;
  vm.getUserLogin = getUserLogin;
  vm.view = view;

  function getUserLogin() {
    return session.user;
  }

  function view(id) {
    $state.go("app.viewProduct", { id: id });
  }
}
