app.controller("HomeController", HomeController);

HomeController.$inject = [
  "$log",
  "$cookies",
  "$translate",
  "$animate",
  "$interval",
  "$sessionStorage",
  "BannerService",
  "ProductGroupService",
  "ProductCategoryService",
  "UserService",
  "ProductService",
  "config",
  "$state",
  "blockUI",
  "$window",
];

function HomeController(
  $log,
  $cookies,
  $translate,
  $animate,
  $interval,
  $sessionStorage,
  BannerService,
  ProductGroupService,
  ProductCategoryService,
  UserService,
  ProductService,
  config,
  $state,
  blockUI,
  $window
) {
  var vm = this;
  var session = $sessionStorage;

  vm.windowWidth = $window.innerWidth;

  /*UI Slide*/
  vm.direction = "left";
  vm.currentIndex = 0;
  vm.slides = [];
  vm.fixeds = [];
  vm.productGroupBanners = [];
  vm.categories = [];
  vm.config = config;

  /*UI variable*/

  /*UI function*/
  vm.init = init();
  vm.switchLang = switchLang;
  vm.setCurrentSlideIndex = setCurrentSlideIndex;
  vm.isCurrentSlideIndex = isCurrentSlideIndex;
  vm.prevSlide = prevSlide;
  vm.nextSlide = nextSlide;
  vm.viewGroup = viewGroup;

  function init() {
    vm.loadingPage = true;

    mapSession(); //Retrive data from session

    if ($cookies.get("lang") == undefined) {
      $cookies.put("lang", "th");
    }
    $translate.use($cookies.get("lang"));

    var parentWidth = $(".container.slider").parent().width();
    var parentHeight = parentWidth * 0.26;
    $(".slider").css("height", parentHeight + "px");

    if (!vm.productGroups) {
      ProductGroupService.getProductGroups(function (response) {
        //Inquiry Product Groups
        session.productGroups = vm.productGroups = response.data;
      });
    }

    // if (!vm.slides || !vm.categories || !vm.fixeds) {
    BannerService.getBanners(function (response) {
      session.slides = vm.slides = response.data.left;
      session.fixeds = vm.fixeds = response.data.right;

      ProductCategoryService.getCategories(function (response) {
        session.categories = vm.categories = response.data;

        vm.loadingPage = false;
      });
    });
    // } else {
    //   vm.loadingPage = false;
    // }
  }

  function mapSession() {
    vm.slides = session.slides;
    vm.categories = session.categories;
    vm.productGroups = session.productGroups;
    vm.fixeds = session.fixeds;
  }

  function switchLang(lang) {
    $translate.use(lang);
    $cookies.put("lang", lang);
  }

  function setCurrentSlideIndex(index) {
    vm.direction = index > vm.currentIndex ? "left" : "right";
    vm.currentIndex = index;
  }

  function isCurrentSlideIndex(index) {
    return vm.currentIndex === index;
  }

  function prevSlide() {
    vm.direction = "left";
    vm.currentIndex =
      vm.currentIndex < vm.slides.length - 1 ? ++vm.currentIndex : 0;
  }

  function nextSlide() {
    vm.direction = "right";
    vm.currentIndex =
      vm.currentIndex > 0 ? --vm.currentIndex : vm.slides.length - 1;
  }

  function viewGroup(groupId) {
    $state.go("app.viewGroup", { id: groupId });
  }

  /* Set time for auto sliding */
  $interval(function () {
    vm.prevSlide();
  }, 10000);
}

app.animation(".slide-animation", function () {
  var parentWidth = $(".container.slider").parent().width();

  return {
    beforeAddClass: function (element, className, done) {
      var scope = element.scope();

      if (className == "ng-hide") {
        var finishPoint = parentWidth;
        if (scope.vm.direction !== "right") {
          finishPoint = -finishPoint;
        }
        TweenMax.to(element, 0.5, { left: finishPoint, onComplete: done });
      } else {
        done();
      }
    },
    removeClass: function (element, className, done) {
      var scope = element.scope();

      if (className == "ng-hide") {
        element.removeClass("ng-hide");

        var startPoint = parentWidth;
        if (scope.vm.direction === "right") {
          startPoint = -startPoint;
        }

        TweenMax.fromTo(
          element,
          0.5,
          { left: startPoint },
          { left: 0, onComplete: done }
        );
      } else {
        done();
      }
    },
  };
});
