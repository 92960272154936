app.factory("CartService", CartService);

CartService.$inject = [
  "$resource",
  "CommonCallback",
  "config",
  "$sessionStorage",
];

function CartService($resource, CommonCallback, config, $sessionStorage) {
  function getUserId() {
    var userId = undefined;
    if ($sessionStorage.user) {
      userId = $sessionStorage.user._id;
    }

    return userId;
  }

  var resource = $resource(
    "",
    {},
    {
      get: {
        method: "POST",
        url: config.apiUrl + "/cart/getData",
      },
      updateCart: {
        method: "POST",
        url: config.apiUrl + "/cart",
      },
    }
  );

  function get(successCallBack) {
    resource.get(
      {
        userId: getUserId(),
      },
      CommonCallback.onSuccess(successCallBack),
      CommonCallback.onError
    );
  }

  function updateCart(productOid, productId, qty, successCallBack) {
    resource.updateCart(
      {
        productId: productId,
        productOid: productOid,
        qty: qty,
        userId: getUserId(),
      },
      CommonCallback.onSuccess(successCallBack),
      CommonCallback.onError
    );
  }

  return {
    updateCart: updateCart,
    get: get,
  };
}
