angular.module("app").controller("PoExDetailController", PoExDetailController);

PoExDetailController.$inject = ['$translate', '$state', '$stateParams', 'POService', 'config', '$sessionStorage', 'Constant', '$window', 'ModalFactory', 'ConfigService'];

function PoExDetailController($translate, $state, $stateParams, POService, config, $sessionStorage, Constant, $window, ModalFactory, ConfigService) {
  var vm = this;
  var session = $sessionStorage;

  //UI Variable
  vm.summary = {};
  vm.navs = [];
  vm.userMenu = 'purchaseOrder';
  vm.param = '';
  vm.canPayment = false;
  vm.paymentBtn = false;
  vm.clicked = true;

  /*UI function*/
  vm.init = init();
  vm.invoiceSelector = invoiceSelector;
  vm.reload = reload;
  vm.payments = payments;

  function init() {

    vm.loadingPage = true;

    session.purchaseOrderId = undefined;

    if ($stateParams.purchaseOrderId == "") {
      $state.go('app.home');
      return;
    }

    Constant.invoiceStatus(function (result) {
      vm.status = result
    })

    vm.param = $stateParams.purchaseOrderId;

    ConfigService.getConfig(function (configData) {
      vm.lineAt = configData.lineAt;
      vm.facebook = configData.facebook;
    })

    POService.get($stateParams.purchaseOrderId, function (response) {
      if (response.responseCode == 0 && response.data != null) {
        vm.po = response.data;
        vm.products = vm.po.items;
        vm.summary = vm.po.summary;
        vm.promotions = vm.po.promotions;

        if (vm.po.invoices.length > 0) {
          for (let index = 0; index < vm.po.invoices.length; index++) {
            const invoice = vm.po.invoices[index];
            if (invoice.status === 10 && (vm.po.customerCreditType === 'CASH' || vm.po.customerCreditType === 'CREDIT') && vm.po.customerType === 'V') {
              vm.paymentBtn = true;
            }

            if (invoice.status === 10) {
              vm.canPayment = true;
            }
          }
        }
      }

      vm.loadingPage = false;
    })
  }

  function invoiceSelector(invoiceNo) {
    session.purchaseOrderId = $stateParams.purchaseOrderId;
    session.customerCreditType = vm.po.customerCreditType;
    session.customerType = vm.po.customerType;

    $state.go('invoiceExDetail', { invoiceNo: invoiceNo });
  }

  function reload() {
    $window.location.reload()
  }

  function payments() {

    session.invoices = [];

    angular.forEach(vm.po.invoices, function (invoice) {
      if (invoice.checked) {
        session.invoices.push('invoice|' + invoice._id);
      }
    });

    if (session.invoices.length > 0) {
      session.purchaseOrderId = $stateParams.purchaseOrderId;
      session.customerCreditType = vm.po.customerCreditType;
      session.customerType = vm.po.customerType;
      $state.go('paymentEx');
    } else {
      $translate('err.payment.noitem.header').then(function (header) {
        $translate('err.payment.noitem.detail').then(function (detail) {
          var config = {
            header: header,
            content1: detail
          }
          ModalFactory.error(config, function (callback) {

          })
        })
      })
    }

  }

}
