angular.module("app").controller("SearchController", SearchController);

SearchController.$inject = [
  "$log",
  "$translate",
  "$stateParams",
  "ProductService",
  "PagingService",
];

function SearchController(
  $log,
  $translate,
  $stateParams,
  ProductService,
  PagingService
) {
  var vm = this;

  /*UI variable*/
  vm.navs = [];
  vm.searchText;
  vm.products = [];
  vm.totalItems = 0;
  vm.totalPage = 0;
  vm.criteria = {};

  vm.paging = {};

  /*UI function*/
  vm.init = init();
  vm.selectedPage = selectedPage;

  function init() {
    vm.loadingPage = true;

    $translate("menu.search").then(function (data) {
      vm.navs[0] = { description: data };
    });

    vm.searchText = $stateParams.text;

    vm.criteria = {
      page: 1,
      itemPerPage: 24,
      searchText: vm.searchText,
    };

    search();

    vm.loadingPage = false;
  }

  function selectedPage(page) {
    vm.criteria.page = page;
    search();
  }

  function search() {
    vm.loadingItem = true;
    ProductService.getProducts(vm.criteria, function (response) {
      //Inquiry Product Groups
      vm.products = response.data;
      vm.totalItems = response.totalItems;
      vm.totalPage = response.totalPage;
      vm.loadingItem = false;

      PagingService.get(
        vm.criteria.page,
        vm.criteria.itemPerPage,
        response.totalItems,
        function (response) {
          //Inquiry Product Groups
          vm.paging = response;
        }
      );
    });
  }
}
