angular.module("app").controller("DeleteModalController", DeleteModalController);

DeleteModalController.$inject = ['$uibModalInstance'];

function DeleteModalController($modalInstance){
    var vm = this;

    vm.ok = ok;
    vm.cancel = cancel;

    function ok(state) {
        $modalInstance.close();
    }

    function cancel(){
        $modalInstance.dismiss("");
    }
}