angular.module("app").controller("PaymentSuccessController", PaymentSuccessController);

PaymentSuccessController.$inject = ['$translate', '$state', 'ModalFactory', '$stateParams', '$sessionStorage', 'InvoiceService', 'Constant', 'UserService', 'blockUI'];

function PaymentSuccessController($translate, $state, ModalFactory, $stateParams, $sessionStorage, InvoiceService, Constant, UserService, blockUI) {
    var vm = this;

    /* UI variable */


    /*UI function*/
    vm.int = init();
    function init() {

    	$sessionStorage.paymentType = undefined;

    	$translate(['success.payment.2c2p.header','success.payment.2c2p.content1','success.payment.2c2p.content2']).then(function (translate) {

       		var config = {
   	            header: translate['success.payment.2c2p.header'],
   	            content1: translate['success.payment.2c2p.content1'],
   	            content2: translate['success.payment.2c2p.content2']
   	        }
   	        ModalFactory.success(config, function () {
   	            $state.go('app.invoice');
   	        })

       });
    }


}
