app.controller("HomePopupModalController", HomePopupModalController);

HomePopupModalController.$inject = ["config", "$uibModalInstance"];

function HomePopupModalController(config, $modalInstance) {
  var vm = this;

  vm.data = config;

  vm.close = close;

  function close() {
    $modalInstance.close();
  }
}
