angular.module("app").factory("DeleteModalFactory", DeleteModalFactory);

DeleteModalFactory.$inject = ['$uibModal'];

function DeleteModalFactory($uibModal){

    function open(thenCallback) {
        open(thenCallback);
    }

    function open(thenCallback) {
        $uibModal.open({
            templateUrl: 'app/modal/delete.modal.html',
            controller: 'DeleteModalController',
            controllerAs: 'vm',
            backdrop: 'static',
            keyboard: false,
            size: "xs"
        }).result.then(function () {
            if (thenCallback) {
                thenCallback();
            }
        });
    }

    return {
        open: open
    }
}