angular.module("app").config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
  $locationProvider.html5Mode(true).hashPrefix("!");
  $urlRouterProvider.otherwise("/ecommerce/home");

  $stateProvider
    .state("app", {
      url: "/ecommerce",
      templateUrl: "app/template/main.html",
      controller: "MainController",
      controllerAs: "mvm",
    })
    .state("app.home", {
      url: "/home",
      templateUrl: "app/home/home.html",
      controller: "HomeController",
      controllerAs: "vm",
    })
    .state("app.register", {
      url: "/register",
      templateUrl: "app/user/register.html",
      controller: "RegisterController",
      controllerAs: "vm",
    })
    .state("app.registerselector", {
      url: "/register/:type",
      templateUrl: "app/user/registerSelector.html",
      controller: "RegisterSelectorController",
      controllerAs: "vm",
    })
    .state("app.registerMember", {
      url: "/register-member",
      templateUrl: "app/user/registerMember.html",
      controller: "RegisterMemberController",
      controllerAs: "vm",
    })

    .state("app.login", {
      url: "/login",
      templateUrl: "app/user/login",
      controller: "LoginController",
      controllerAs: "vm",
    })
    .state("app.searchProduct", {
      url: "/product/search/:text",
      templateUrl: "app/product/search.html",
      controller: "SearchController",
      controllerAs: "vm",
    })
    .state("app.information", {
      url: "/information",
      templateUrl: "app/user/information.html",
      controller: "InformationController",
      controllerAs: "vm",
    })
    .state("app.inqProduct", {
      url: "/product/inq/:level/:id",
      templateUrl: "app/product/inq.html",
      controller: "InqController",
      controllerAs: "vm",
    })
    .state("app.inqProduct2", {
      url: "/product/inq/:level/:id/:brandOid",
      templateUrl: "app/product/inq.html",
      controller: "InqController",
      controllerAs: "vm",
    })
    .state("app.brandInqProduct", {
      url: "/brand/inq/:level/:id",
      templateUrl: "app/product/brand-inq.html",
      controller: "BrandInqController",
      controllerAs: "vm",
    })
    .state("app.viewProduct", {
      url: "/product/view/:id",
      templateUrl: "app/product/view.html",
      controller: "ViewController",
      controllerAs: "vm",
    })
    .state("app.viewGroup", {
      url: "/product/view-group/:id",
      templateUrl: "app/product/view-group.html",
      controller: "ViewGroupController",
      controllerAs: "vm",
    })
    .state("app.cart", {
      url: "/cart",
      templateUrl: "app/cart/cart.html",
      controller: "CartController",
      controllerAs: "vm",
    })
    .state("app.purchaseOrder", {
      url: "/purchaseOrder",
      templateUrl: "app/purchaseOrder/purchaseOrder.html",
      controller: "PurchaseOrderController",
      controllerAs: "vm",
    })
    .state("app.purchaseOrderSelector", {
      url: "/purchaseOrder/:purchaseOrderId",
      templateUrl: "app/purchaseOrder/purchaseOrderSelector.html",
      controller: "PurchaseOrderSelectorController",
      controllerAs: "vm",
    })
    .state("app.purchaseOrderTemplate", {
      url: "/purchaseOrderTemplate",
      templateUrl: "app/purchaseOrder/purchaseOrderTemplate.html",
      controller: "PurchaseOrderTemplateController",
      controllerAs: "vm",
    })
    .state("app.purchaseOrderTemplateSelector", {
      url: "/purchaseOrderTemplateSelector/:poTemplateId",
      templateUrl: "app/purchaseOrder/purchaseOrderTemplateSelector.html",
      controller: "PurchaseOrderTemplateSelectorController",
      controllerAs: "vm",
    })
    .state("app.wishlist", {
      url: "/wishlist",
      templateUrl: "app/user/wishlist.html",
      controller: "WishlistController",
      controllerAs: "vm",
    })
    .state("app.invoice", {
      url: "/invoice",
      templateUrl: "app/invoice/invoice.html",
      controller: "InvoiceController",
      controllerAs: "vm",
    })
    .state("app.invoiceSelector", {
      url: "/invoice/:invoiceNo",
      templateUrl: "app/invoice/invoiceSelector.html",
      controller: "InvoiceSelectorController",
      controllerAs: "vm",
    })
    .state("app.shipment", {
      url: "/shipment",
      templateUrl: "app/cart/shipment.html",
      controller: "ShipmentController",
      controllerAs: "vm",
    })
    .state("app.payment", {
      url: "/payment",
      templateUrl: "app/invoice/payment.html",
      controller: "PaymentController",
      controllerAs: "vm",
    })
    .state("app.payment-success", {
      url: "/payment-success",
      templateUrl: "app/invoice/payment-success.html",
      controller: "PaymentSuccessController",
      controllerAs: "vm",
    })
    .state("app.upload", {
      url: "/upload",
      templateUrl: "app/upload/upload.html",
      controller: "UploadController",
      controllerAs: "vm",
    })
    .state("app.changePassword", {
      url: "/changePassword",
      templateUrl: "app/user/changePassword.html",
      controller: "ChangePasswordController",
      controllerAs: "vm",
    })
    .state("app.forgotPassword", {
      url: "/forgotPassword",
      templateUrl: "app/user/forgotPassword.html",
      controller: "ForgotPasswordController",
      controllerAs: "vm",
    })
    .state("app.resetPassword", {
      url: "/resetPassword/:key",
      templateUrl: "app/user/resetPassword.html",
      controller: "ResetPasswordController",
      controllerAs: "vm",
    })
    .state("app.welcome", {
      url: "/welcome/:key",
      templateUrl: "app/user/fristTime.html",
      controller: "FristTimeController",
      controllerAs: "vm",
    })
    .state("app.howto", {
      url: "/howto",
      templateUrl: "app/static/howto.html",
      controller: "HowtoController",
      controllerAs: "vm",
    })
    .state("app.contact", {
      url: "/contact",
      templateUrl: "app/static/contact.html",
      controller: "ContactController",
      controllerAs: "vm",
    })
    .state("app.aboutus", {
      url: "/aboutus",
      templateUrl: "app/static/aboutus.html",
      controller: "AboutUsController",
      controllerAs: "vm",
    })
    .state("app.job", {
      url: "/job",
      templateUrl: "app/static/job.html",
      controller: "JobController",
      controllerAs: "vm",
    })
    .state("poExDetail", {
      url: "/poExDetail/:purchaseOrderId",
      templateUrl: "app/external-flow/poExDetail.html",
      controller: "PoExDetailController",
      controllerAs: "vm",
    })
    .state("invoiceExDetail", {
      url: "/invoiceExDetail/:invoiceNo",
      templateUrl: "app/external-flow/invoiceExDetail.html",
      controller: "InvoiceExDetailController",
      controllerAs: "vm",
    })
    .state("paymentEx", {
      url: "/paymentEx",
      templateUrl: "app/external-flow/payment.html",
      controller: "PaymentExController",
      controllerAs: "vm",
    })
    .state("payment-success-ex", {
      url: "/payment-success-ex",
      templateUrl: "app/external-flow/payment-success.html",
      controller: "PaymentSuccessExController",
      controllerAs: "vm",
    });
}
