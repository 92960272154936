angular
  .module('app')
  .controller('WishlistController', WishlistController);

WishlistController.$inject = ['$log', '$translate', '$sessionStorage', 'ProductService', 'PagingService', 'UserService'];

function WishlistController($log, $translate, $sessionStorage, ProductService, PagingService, UserService) {

  var vm = this;
  var session = $sessionStorage;

  /*UI variable*/
  vm.navs = [];
  vm.products = [];
  vm.totalItems = 0;
  vm.totalPage = 0;
  vm.criteria = {};
  vm.wishlistMenu = true;
  vm.userMenu = 'wishlist';

  vm.paging = {};


  /*UI function*/
  vm.init = init();
  vm.selectedPage = selectedPage;
  vm.wishlist = wishlist;

  function init() {
	  
	if (!session.user) {
		$state.go('app.home');
	}

    $translate('menu.wishlist').then(function (data) {
      vm.navs[0] = { description: data }
    });

    vm.criteria = {
      page: 1,
      itemPerPage: 36
    };

    search();

  }

  function selectedPage(page) {
    vm.criteria.page = page;
    search();
  }


  function search() {
	  
	vm.loadingPage = true;
	
    ProductService.getWishlistProduct(vm.criteria, function (response) { //Inquiry Product Groups
      vm.loadingPage = false;
      vm.products = response.data;
      vm.totalItems = response.totalItems;
      vm.totalPage = response.totalPage;

      PagingService.get(vm.criteria.page, vm.criteria.itemPerPage, response.totalItems, function (response) { //Inquiry Product Groups
        vm.paging = response;
      });
    });


  }

  function wishlist(product) {
    UserService.wishlist(session.user._id, product._id, function (result) {
      product.wishlist = !product.wishlist;
      search();
    })

  }

}

