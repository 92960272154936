angular.module("app").service("PoTemplateService", PoTemplateService);

PoTemplateService.$inject = ['$resource', 'CommonCallback', 'config'];

function PoTemplateService($resource, CommonCallback, config){
    
	var resource = $resource('', {}, {
		'getTemplate': {
			method: 'GET',
			url: config.apiUrl + '/poTemplate/:_id'
		},
		'deleteTemplate':{
			method: 'DELETE',
			url: config.apiUrl + '/poTemplate/:_id'
		},
		'createTemplate': {
			method: 'POST',
			url: config.apiUrl + '/poTemplate'
		},
		'search':{
			method: 'POST',
			url: config.apiUrl + '/poTemplate/search'
		},
		'updateCart': {
			method: 'POST',
			url: config.apiUrl + '/poTemplate/updateCart'
		}
	});

    function getTemplate(_id, successCallBack) {
		resource.getTemplate({
			_id: _id
		},
			CommonCallback.onSuccess(successCallBack),
			CommonCallback.onError);
	}

	function createTemplate(template, successCallBack) {
		resource.createTemplate(template,
			CommonCallback.onSuccess(successCallBack),
			CommonCallback.onError);
	}

	function search(data, successCallBack){
		resource.search(data,
			CommonCallback.onSuccess(successCallBack),
			CommonCallback.onError);
	}

	function deleteTemplate(_id, successCallBack){
		resource.deleteTemplate({
			_id: _id
		},
			CommonCallback.onSuccess(successCallBack),
			CommonCallback.onError);
	}

	function updateCart(_id, successCallBack){
		resource.updateCart({
			_id: _id
		},
			CommonCallback.onSuccess(successCallBack),
			CommonCallback.onError);
	}

    return {
        getTemplate : getTemplate,
        createTemplate : createTemplate,
		search : search,
		deleteTemplate : deleteTemplate,
		updateCart : updateCart
    }
}