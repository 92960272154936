angular
  .module("app")
  .controller("PurchaseOrderController", PurchaseOrderController);

PurchaseOrderController.$inject = [
  "$translate",
  "$state",
  "DateTimepickerFactory",
  "POService",
  "PagingService",
  "$sessionStorage",
  "UserService",
];

function PurchaseOrderController(
  $translate,
  $state,
  DateTimepickerFactory,
  POService,
  PagingService,
  $sessionStorage,
  UserService
) {
  var vm = this;
  var session = $sessionStorage;

  /*UI variable*/
  vm.search = {};
  vm.criteriaType = 1; // 1 = cureent month, 2 = old month, 3 from date - to date
  vm.oldMonth = []; //เดือนปัจจุบันย้อนหลังไป 12 เดือน
  vm.fromDate = new Date();
  vm.toDate = new Date();
  vm.clicked = false;
  vm.criteria = {};
  vm.navs = [];
  vm.userMenu = "purchaseOrder";

  /*UI function*/
  vm.int = init();
  vm.submitSearch = submitSearch;
  vm.selectedPage = selectedPage;
  vm.selector = selector;
  vm.changeCriteriaType = changeCriteriaType;
  vm.openCriteria = openCriteria;

  function init() {
    vm.loadingPage = true;

    if (!session.user) {
      $state.go("app.home");
    }

    vm.criteria = {
      itemPerPage: 20,
      fromDate: new Date(),
      toDate: new Date(),
      page: 1,
      userId: session.user._id,
      custCode: session.user.dealerId,
    };

    $translate("menu.purchaseOrder").then(function (data) {
      vm.navs[0] = { description: data };
    });

    setOldMonth();
    DateTimepickerFactory.initialDatePicker(vm);

    if (session.criteria && session.criteria.key == "PurchaseOrderController") {
      vm.criteria = session.criteria;
      vm.clicked = vm.criteria.clicked;
      vm.criteriaType = vm.criteria.criteriaType ? vm.criteria.criteriaType : 1;
      vm.fromDate = new Date(vm.criteria.fromDate);
      vm.toDate = new Date(vm.criteria.toDate);
    }

    submitSearch();
  }

  function selectedPage(page) {
    vm.criteria.page = page;
    submitSearch();
  }

  function changeCriteriaType(id) {
    vm.criteriaType = id;
    vm.criteria.page = 1;
  }

  function selector(id) {
    $state.go("app.purchaseOrderSelector", { purchaseOrderId: id });
  }

  function setOldMonth() {
    var currentDate = new Date();
    currentDate.setDate(1);
    for (var i = 0; i < 12; i++) {
      var month = currentDate.getMonth() - i;
      vm.oldMonth.push({
        value:
          (month > 0 ? month : month + 12) +
          "/" +
          "1/" +
          (month > 0
            ? currentDate.getFullYear()
            : currentDate.getFullYear() - 1),
        month: "months." + (month > 0 ? month : month + 12),
        year:
          month > 0 ? currentDate.getFullYear() : currentDate.getFullYear() - 1,
      });
    }
    vm.search.oldMonth = vm.oldMonth[0].value;
  }

  function submitSearch() {
    vm.contentLoad = true;
    if (vm.criteriaType == 1) {
      var date = new Date();
      date.setHours(0, 0, 0, 0);
      vm.criteria.fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
      vm.criteria.toDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    } else if (vm.criteriaType == 2) {
      var date = new Date(vm.search.oldMonth);
      vm.criteria.fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
      vm.criteria.toDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    } else {
      if (vm.fromDate == null || vm.toDate == null) return;
      vm.criteria.fromDate = vm.fromDate;
      vm.criteria.toDate = vm.toDate;
    }
    vm.criteria.toDate.setHours(23, 59, 59);
    POService.search(vm.criteria, function (response) {
      if (response.responseCode == 0) {
        vm.po = response.data;
        PagingService.get(
          vm.criteria.page,
          vm.criteria.itemPerPage,
          response.totalItems,
          function (response) {
            vm.paging = response;
            saveCriteria();
            vm.contentLoad = vm.loadingPage = false;
          }
        );
      } else {
        vm.contentLoad = vm.loadingPage = false;
      }
    });
  }

  function openCriteria() {
    vm.clicked = !vm.clicked;
    saveCriteria();
  }

  function saveCriteria() {
    vm.criteria.key = "PurchaseOrderController";
    vm.criteria.criteriaType = vm.criteriaType;
    vm.criteria.clicked = vm.clicked;
    session.criteria = vm.criteria;
  }
}
