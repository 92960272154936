app.factory('BannerService', BannerService);

BannerService.$inject = [ '$resource', 'CommonCallback', 'config' ];

function BannerService($resource, CommonCallback, config) {
	
	var resource = $resource('', {}, {
			'getBanners': { 
	        	method: 'GET',
	        	isArray: false,
	        	url: config.apiUrl+'/banner'
	        },
	        'getBanners2': { 
	        	method: 'GET',
	        	isArray: false,
	        	url: config.apiUrl+'/banner2'
	        }
	    });
		
	function getBanners( successCallBack) {
		resource.getBanners({
		}, 
		CommonCallback.onSuccess(successCallBack), 
		CommonCallback.onError);
	}
	
	function getBanners2( successCallBack) {
		resource.getBanners2({
		}, 
		CommonCallback.onSuccess(successCallBack), 
		CommonCallback.onError);
	}
		
		
		
	    return {
	    	getBanners: getBanners,
	    	getBanners2: getBanners2
	    };
}
