app.factory('ProductGroupService', ProductGroupService);

ProductGroupService.$inject = ['$resource', 'CommonCallback', 'config', '$sessionStorage'];

function ProductGroupService($resource, CommonCallback, config, $sessionStorage) {

  var email = undefined;
  if ($sessionStorage.user) {
    email = $sessionStorage.user._id;
  }

  var resource = $resource('', {}, {
    'get': {
      method: 'GET',
      url: config.apiUrl + '/productGroup/:id'
    },
    'getProductGroups': {
      method: 'GET',
      isArray: false,
      url: config.apiUrl + '/productGroup'
    }
  });

  function get(id, successCallBack) {
    resource.get({
      id: id
    },
      CommonCallback.onSuccess(successCallBack),
      CommonCallback.onError);
  }

  function getProductGroups(successCallBack) {

    if ($sessionStorage.user) {
      email = $sessionStorage.user._id;
    }

    resource.getProductGroups({
      email: email
    },
      CommonCallback.onSuccess(successCallBack),
      CommonCallback.onError);
  }

  function getProductGroupBanners(successCallBack) {

    var data = {
      data: [
        { image: 'resources/images/test/group-banner_1.jpg' },
        { image: 'resources/images/test/group-banner_2.jpg' },
        { image: 'resources/images/test/group-banner_3.jpg' },
        { image: 'resources/images/test/group-banner_4.jpg' }
      ]
    };

    successCallBack(data);
  }

  return {
    getProductGroups: getProductGroups,
    getProductGroupBanners: getProductGroupBanners,
    get: get
  };
}
