angular.module("app").config(conf).constant("config", constant());

function constant() {
  var config = {};

  config.http = "https://";
  config.apiServer = "yongcharoen.co.th";
  // config.apiServer = "10.10.2.10:3109";
  // config.apiServer = "test-ecm.conxo-ies.com";
  config.apiUrl = "https://yongcharoen.co.th/api";
  config.productUrl = "/img/product/000";
  config.groupHeaderUrl = "/img/group-header";
  config.lineAt = "https://line.me/R/ti/p/~%40pqn1790x"; //PROD
  // config.lineAt = "https://line.me/R/ti/p/~%40320hbhrj";
  config.facebook = null;

  return config;
}

function conf($translateProvider, blockUIConfig) {
  $translateProvider.useStaticFilesLoader({
    prefix: "i18n/",
    suffix: ".json",
  });

  $translateProvider.preferredLanguage("th");

  $translateProvider.useSanitizeValueStrategy("escaped");

  // Change the default overlay message
  blockUIConfig.message = "Please wait...";

  // Change the default delay to 100ms before the blocking is visible
  blockUIConfig.delay = 10;

  blockUIConfig.autoBlock = false;
}
