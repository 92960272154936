angular
  .module("app")
  .controller("FromAddressModalController", FromAddressModalController);

FromAddressModalController.$inject = [
  "config",
  "$uibModalInstance",
  "DateTimepickerFactory",
  "Constant",
  "$sessionStorage",
  "ProvinceService",
];

function FromAddressModalController(
  config,
  $modalInstance,
  DateTimepickerFactory,
  Constant,
  $sessionStorage,
  ProvinceService
) {
  var vm = this;
  var session = $sessionStorage;

  //UI Variable
  vm.data = {};

  vm.header = "ข้อมูลที่อยู่";

  //UI Function
  vm.init = init();
  vm.cancel = cancel;
  vm.save = save;
  vm.invalidTel = false;
  vm.isNonVat;

  function init() {
    console.log("INIT");

    vm.header = config.header;

    vm.data = config.data;

    console.log(session.user.vatType);

    if (session.user.vatType == "NONVAT" || session.user.vatType == "A") {
      vm.isNonVat = true;
      vm.data.type = "Shipping";
    } else {
      vm.isNonVat = false;
    }

    if (!vm.data && config.type)
      vm.data = {
        type: config.type,
      };

    if (vm.data && vm.data.province) {
      vm.data.provinceTemp = vm.data.province.provinceId;
    }

    if (!session.provinces) {
      ProvinceService.getProvince(function (response) {
        session.provinces = vm.provinces = response.data;
      });
    } else {
      vm.provinces = session.provinces;
    }
  }

  function cancel() {
    $modalInstance.dismiss("");
  }

  function save() {
    vm.invalidTel = false;

    if (vm.data.type == "Tax" && !/^[0-9]{13}$/.test(vm.data.taxId)) {
      vm.addressForm.taxId.$invalid = true;
      return;
    }

    if (!/^[0-9]{5}$/.test(vm.data.postcode)) {
      vm.addressForm.postcodeTemp.$invalid = true;
      return;
    }

    if (vm.data.tel.length < 9) {
      vm.addressForm.deliveryTelTemp.$invalid = true;
      vm.invalidTel = true;
      return;
    }

    if (vm.addressForm.$invalid) return;

    if (vm.data.type == "Shipping") {
      vm.data.taxId = undefined;
    }

    setProviceName(vm.data.provinceTemp);

    $modalInstance.close(vm.data);
  }

  function setProviceName(id) {
    angular.forEach(session.provinces, function (province) {
      if (province.objectId == id) {
        if (!vm.data.province) vm.data.province = {};
        vm.data.province.provinceId = province.objectId;
        vm.data.province.name = province.name;
      }
    });
  }
}
