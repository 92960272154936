angular.module("app").controller("PaymentExController", PaymentExController);

PaymentExController.$inject = [
  "$translate",
  "$state",
  "ModalFactory",
  "$stateParams",
  "$sessionStorage",
  "InvoiceService",
  "Constant",
  "UserService",
  "blockUI",
  "ConfigService",
];

function PaymentExController(
  $translate,
  $state,
  ModalFactory,
  $stateParams,
  $sessionStorage,
  InvoiceService,
  Constant,
  UserService,
  blockUI,
  ConfigService
) {
  var vm = this;
  var session = $sessionStorage;

  /* UI variable */
  vm.userMenu = "invoice";
  vm.navs = [];
  vm.paymentType = 2;
  vm.summary = {};
  vm.dataInvoice = {};
  vm.invoiceTemps = [];
  vm.banks = [];
  vm.user = session.user;
  vm.customerCreditType = session.customerCreditType;

  /*UI function*/
  vm.int = init();
  vm.changePaymentType = changePaymentType;
  vm.back = back;
  vm.uploadSlip = uploadSlip;
  vm.credit = credit;
  vm.tctp = tctp;

  function init() {
    vm.loadingPage = true;

    if (!session.invoices) {
      var config = {};
      config.header = "ไม่พบรายการ Invoice ที่เลือกเข้ามา";
      config.content1 = "กลับไปเลือกใหม่";

      ModalFactory.error(config, function (result) {
        $state.go("app.invoice");
      });
    } else {
      if (session.paymentType) {
        vm.paymentType = session.paymentType;
      }

      Constant.banks(function (banks) {
        vm.banks = banks;
      });

      vm.dataInvoice.invoices = session.invoices;

      InvoiceService.gets(vm.dataInvoice, function (result) {
        vm.loadingPage = false;
        vm.invoices = result.data.invoices;
        vm.summary = result.data.summary;
        if (vm.paymentType == 3) {
          ConfigService.get(function (result) {
            if (result.data.tctpCharge) {
              var tctpCharge = result.data.tctpCharge.value;
              var totalAmt = vm.summary.totalMemberPrice + vm.summary.shiping;
              vm.summary.fee = (totalAmt * tctpCharge) / 100;
            }
          });
        } else {
          vm.summary.fee = 0;
        }
      });

      session.invoices = undefined;
      session.paymentType = undefined;
    }
  }

  function changePaymentType(type) {
    vm.paymentType = type;

    if (vm.paymentType == 3) {
      ConfigService.get(function (result) {
        if (result.data.tctpCharge) {
          var tctpCharge = result.data.tctpCharge.value;
          var totalAmt =
            vm.summary.totalMemberPrice +
            vm.summary.shiping -
            vm.summary.discount;
          vm.summary.fee = (totalAmt * tctpCharge) / 100;
        }
      });
    } else {
      vm.summary.fee = 0;
    }
  }

  function back() {
    window.history.back();
  }

  function payment(callback) {
    blockUI.start();

    var invoices = [];

    angular.forEach(vm.invoices, function (invoice) {
      invoices.push(invoice._id);
    });

    var d = new Date();
    var paymentKey = d.getTime();

    var data = {};
    data.invoices = invoices;
    data.paymentType = vm.paymentType;
    data.uploadSlip = vm.uploadSlip;
    data.totalMemberPrice = vm.summary.totalMemberPrice + vm.summary.shiping;
    data.paymentKey = paymentKey;

    InvoiceService.payment(data, function (result) {
      blockUI.stop();
      callback(result);
    });
  }

  function credit() {
    payment(function (result) {
      $translate([
        "success.payment.credit.header",
        "success.payment.credit.content1",
        "success.payment.credit.content2",
      ]).then(function (translate) {
        var config = {
          header: translate["success.payment.credit.header"],
          content1: translate["success.payment.credit.content1"],
          content2: translate["success.payment.credit.content2"],
        };
        ModalFactory.success(config, function () {
          $state.go("poExDetail", { purchaseOrderId: session.purchaseOrderId });
        });
      });
    });
  }

  function tctp() {
    blockUI.start();

    var summary =
      vm.summary.totalMemberPrice +
      vm.summary.fee +
      vm.summary.shiping -
      vm.summary.discount;

    var d = new Date();
    var paymentKey = d.getTime();

    var invoices = [];

    angular.forEach(vm.invoices, function (invoice) {
      invoices.push(invoice._id);
    });

    var data = {};
    data.invoices = invoices;
    data.paymentType = vm.paymentType;
    data.uploadSlip = vm.uploadSlip;
    data.paymentKey = paymentKey;
    data.totalMemberPrice =
      vm.summary.totalMemberPrice + vm.summary.shiping - vm.summary.discount;
    data.fee = vm.summary.fee;

    InvoiceService.savePayment(data, function (result) {
      ConfigService.getConfig(function (configData) {
        var myEl = angular.element(document.querySelector("#tctp"));
        $("#tctp").append(
          '<input type="hidden" value=' +
            paymentKey +
            ' id="order_id" name="order_id" />'
        );
        $("#tctp").append(
          '<input type="hidden" value=' +
            summary +
            ' id="netAmount" name="netAmount" />'
        );
        $("#tctp").append(
          '<input type="hidden" value="th" id="default_lang" name="default_lang" />'
        );
        $("#tctp").append(
          '<input type="hidden" value="xxx@xxx.com" id="customer_email" name="customer_email" />'
        );
        $("#tctp").append(
          '<input type="hidden" value="' +
            configData.apiUrl +
            '/payment/result2" id="result_url_1" name="result_url_1" />'
        );

        $("#tctp").submit();

        session.invoices = vm.dataInvoice.invoices;
        session.paymentType = vm.paymentType;
      });
    });
  }

  function uploadSlip() {
    var amount =
      vm.summary.totalMemberPrice + vm.summary.shiping - vm.summary.discount;

    ModalFactory.uploadSlip({ amount: amount }, function (result) {
      vm.uploadSlip = result;

      payment(function (result) {
        $translate([
          "success.payment.uploadSlip.header",
          "success.payment.uploadSlip.content1",
          "success.payment.uploadSlip.content2",
        ]).then(function (translate) {
          var config = {
            header: translate["success.payment.uploadSlip.header"],
            content1: translate["success.payment.uploadSlip.content1"],
            content2: translate["success.payment.uploadSlip.content2"],
          };
          ModalFactory.success(config, function () {
            $state.go("poExDetail", {
              purchaseOrderId: session.purchaseOrderId,
            });
          });
        });
      });
    });
  }
}
