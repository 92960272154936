app.service('PagingService', PagingService);

PagingService.$inject = [];

function PagingService() {
   
    function get(page, itemsPerPage, totalItems,callback) {
    	
    	var paging = {
    			page: page,
    			itemsPerPage: itemsPerPage,
    			totalItems: totalItems
    	}
    	
    	
    	if( (totalItems % itemsPerPage) == 0 ){
    		paging.max = parseInt((totalItems / itemsPerPage));
    	}else{
    		paging.max = parseInt((totalItems / itemsPerPage)+1);
    	}
    	
    	paging.page = parseInt(page,10);
    	paging.pages = [];
    	var btnCount = 3;
    	var max = paging.max;
    	var current =  paging.page;
    	paging.isHaveFrist = false;
    	paging.isHaveLast = false;
    	
    	if( max != btnCount+1 ){
    		
    		if( max > btnCount ){
    			
    			if( current >= btnCount ){
    				paging.isHaveFrist = true;
    			}
    			
    			if( (max - btnCount) + 1 >= current  ){
    				paging.isHaveLast = true;
    			}
    			
    			if( btnCount - current <= 1 ){
    				if( max - current  > 1 ){
    					for(var i=current-1 ; i<=current+1 ; i++) {
    						paging.pages.push(i);
    					}
    				}else{
    					for(var i=max-btnCount ; i<=max ; i++) {
    						paging.pages.push(i);
    					}
    				}
    			}else{
    				paging.isHaveFrist = false;
    				for(var i=1 ; i<=btnCount ; i++) {
    					paging.pages.push(i);
    				}
    			}
    				
    			
    			
    		}else{
    			paging.pages = []; 
    			for(var i=1 ; i<=max ; i++) {
    				paging.pages.push(i);
    			}
    		}
    	
    	}else{
    		paging.pages = []; 
    		for(var i=1 ; i<max ; i++) {
    			paging.pages.push(i);
    		}
    		paging.isHaveLast = true;
    	}
    	
    	callback(paging);
    }
    
    return{
    	get: get
    }
}