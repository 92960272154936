app.controller('JobController', JobController);

JobController.$inject = ['$translate', '$sessionStorage', 'JobService'];

function JobController($translate, $sessionStorage, JobService) {
	
	var vm = this;
	var session = $sessionStorage;
	vm.navs = [];
	vm.jobs = [];
	
	vm.init = init();
	
	function init(){
		
		vm.loadingPage = true;
		
		$translate('menu.job').then(function (data) {
            vm.navs[0] = { description: data }
        });
		
		JobService.gets(function(response){
			vm.jobs = response.data;
			vm.loadingPage = false;
		})
	}
	
}