angular.module("app").factory("ProductCategoryService", ProductCategoryService);

ProductCategoryService.$inject = ["$resource", "CommonCallback", "config"];

function ProductCategoryService($resource, CommonCallback, config) {
  var resource = $resource(
    "",
    {},
    {
      getCategories: {
        method: "GET",
        isArray: false,
        url: config.apiUrl + "/category/getActive",
      },
      getCategory: {
        method: "GET",
        isArray: false,
        url: config.apiUrl + "/category/:id",
      },
    }
  );

  function getCategories(successCallBack) {
    resource.getCategories(
      {},
      CommonCallback.onSuccess(successCallBack),
      CommonCallback.onError
    );
  }

  function getCategory(id, successCallBack) {
    resource.getCategory(
      {
        id: id,
      },
      CommonCallback.onSuccess(successCallBack),
      CommonCallback.onError
    );
  }

  return {
    getCategories: getCategories,
    getCategory: getCategory,
  };
}
