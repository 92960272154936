angular
  .module("app")
  .controller("InvoiceExDetailController", InvoiceExDetailController);

InvoiceExDetailController.$inject = [
  "$translate",
  "$state",
  "$stateParams",
  "InvoiceService",
  "$sessionStorage",
  "Constant",
  "UserService",
];

function InvoiceExDetailController(
  $translate,
  $state,
  $stateParams,
  InvoiceService,
  $sessionStorage,
  Constant,
  UserService
) {
  var vm = this;
  var session = $sessionStorage;

  /*UI variable*/
  vm.products = [];
  vm.summary = {};
  // invoice status
  //         1.Pending (รอรับชำระ)
  //         2.Waiting (ตรวจสอบยอดโอน)
  //         3.Delivery Process (กำลังจัดส่ง)
  //         4.Complete (จัดส่งแล้ว)
  //         5.Cancel (ยกเลิก)
  vm.status;
  /* Nav Bar Setup */
  vm.navs = [];
  vm.invoice = {};
  vm.userMenu = "invoice";
  vm.user = session.user;
  vm.customerCreditType = "";
  vm.customerType = "";

  /*UI function*/
  vm.init = init();
  vm.payment = payment;

  function init() {
    vm.loadingPage = true;
    vm.customerCreditType = session.customerCreditType;
    vm.customerType = session.customerType;

    InvoiceService.get($stateParams.invoiceNo, function (result) {
      vm.loadingPage = false;
      vm.invoice = result.data;
      vm.products = vm.invoice.items;
      vm.summary = vm.invoice.summary;

      //        	if( vm.invoice.fee ){
      //        		vm.summary.fee = vm.invoice.fee;
      //        	}

      //        	if( vm.invoice.userId == session.user._id ){
      //        		$state.go('app.home');
      //        		return;
      //        	}
    });

    Constant.invoiceStatus(function (result) {
      vm.status = result;
    });
  }

  function payment() {
    session.invoices = [];
    session.invoices.push("invoice|" + $stateParams.invoiceNo);

    $state.go("paymentEx");
  }
}
