app.controller('AboutUsController', AboutUsController);

AboutUsController.$inject = ['$translate', '$sessionStorage'];

function AboutUsController($translate, $sessionStorage) {
	
	var vm = this;
	var session = $sessionStorage;
	vm.navs = [];
	
	vm.init = init();
	
	function init(){
		$translate('menu.aboutus').then(function (data) {
            vm.navs[0] = { description: data }
        });
	}
	
}