angular.module("app").factory("TestService", TestService);

TestService.$inject = ["$resource", "CommonCallback", "config"];

function TestService($resource, CommonCallback, config) {
  var resource = $resource(
    "",
    {},
    {
      upload: {
        method: "POST",
        headers: { "Content-Type": undefined },
        url: config.apiUrl + "/upload",
      },
      post: {
        method: "POST",
        url: config.apiUrl + "/test",
      },
      connect: {
        method: "GET",
        url: config.apiUrl + "/connect",
      },
    }
  );

  function upload(data, successCallBack) {
    resource.upload(
      data,
      CommonCallback.onSuccess(successCallBack),
      CommonCallback.onError
    );
  }

  function post(successCallBack) {
    resource.post(
      { text: "Hi" },
      CommonCallback.onSuccess(successCallBack),
      CommonCallback.onError
    );
  }

  function connect(successCallBack) {
    resource.connect(
      null,
      CommonCallback.onSuccess(successCallBack),
      CommonCallback.onConnectionError
    );
  }

  return {
    upload: upload,
    post: post,
    connect: connect,
  };
}
