angular
  .module("app")
  .controller(
    "PurchaseOrderSelectorController",
    PurchaseOrderSelectorController
  );

PurchaseOrderSelectorController.$inject = [
  "$translate",
  "$state",
  "$stateParams",
  "POService",
  "config",
  "$sessionStorage",
  "Constant",
  "UserService",
];

function PurchaseOrderSelectorController(
  $translate,
  $state,
  $stateParams,
  POService,
  config,
  $sessionStorage,
  Constant,
  UserService
) {
  var vm = this;
  var session = $sessionStorage;

  //UI Variable
  vm.summary = {};
  vm.navs = [];
  vm.userMenu = "purchaseOrder";

  /*UI function*/
  vm.init = init();
  vm.back = back;
  vm.invoiceSelector = invoiceSelector;

  function init() {
    vm.loadingPage = true;

    if (!session.user) {
      $state.go("app.home");
    }

    vm.user = session.user;

    if ($stateParams.purchaseOrderId == "") {
      $state.go("app.home");
      return;
    }

    $translate("menu.purchaseOrder").then(function (data) {
      vm.navs[0] = { description: data, href: "/ecommerce/purchaseOrder" };
      vm.navs[1] = { description: $stateParams.purchaseOrderId };
    });

    Constant.invoiceStatus(function (result) {
      vm.status = result;
    });

    POService.get($stateParams.purchaseOrderId, function (response) {
      if (response.responseCode == 0) {
        vm.loadingPage = false;

        if (response.data.custCode != session.user.dealerId) {
          $state.go("app.home");
          return;
        } else {
          vm.po = response.data;
          vm.products = vm.po.items;
          vm.summary = vm.po.summary;
          vm.promotions = vm.po.promotions;
        }
      }
    });
  }

  function invoiceSelector(invoiceNo) {
    $state.go("app.invoiceSelector", { invoiceNo: invoiceNo });
  }

  function back() {
    $state.go("app.purchaseOrder");
  }
}
