angular.module("app").factory("ModalFactory", ModalFactory);

ModalFactory.$inject = ["$uibModal"];

function ModalFactory($modal) {
  function homePopup(config, thenCallback) {
    config.size = "lg";
    config.templateUrl = "app/modal/homePopup.modal.html";
    config.controller = "HomePopupModalController";
    config.backdrop = "static";
    open(config, thenCallback);
  }

  function menuPopup(config, thenCallback) {
    config.size = "lg";
    config.templateUrl = "app/modal/menu.modal.html";
    config.controller = "MenuModalController";
    config.backdrop = "dianamic";
    open(config, thenCallback);
  }

  function success(config, thenCallback) {
    config.size = "lg";
    config.templateUrl = "app/modal/success.modal.html";
    config.controller = "SuccessModalController";
    config.backdrop = "static";
    open(config, thenCallback);
  }

  function error(config, thenCallback) {
    config.size = "lg";
    config.templateUrl = "app/modal/error.modal.html";
    config.controller = "ErrorModalController";
    config.backdrop = "static";
    open(config, thenCallback);
  }

  function uploadSlip(config, thenCallback) {
    config.size = "xs";
    config.templateUrl = "app/modal/uploadSlip.modal.html";
    config.controller = "UploadSlipModalController";
    config.backdrop = "dianamic";
    open(config, thenCallback);
  }

  function formAddress(config, thenCallback) {
    config.size = "xs";
    config.templateUrl = "app/modal/formAddress.modal.html";
    config.controller = "FromAddressModalController";
    config.backdrop = "static";
    open(config, thenCallback);
  }

  function selectAddress(config, thenCallback) {
    config.size = "xs";
    config.templateUrl = "app/modal/selectAddress.modal.html";
    config.controller = "SelectAddressModalController";
    config.backdrop = "dianamic";
    open(config, thenCallback);
  }

  function selectTemplate(config, thenCallback) {
    config.size = "xs";
    config.templateUrl = "app/modal/selectTemplate.modal.html";
    config.controller = "SelectTemplateModalController";
    config.backdrop = "static";
    open(config, thenCallback);
  }

  function saveTemplate(config, thenCallback) {
    config.size = "xs";
    config.templateUrl = "app/modal/saveTemplate.modal.html";
    config.controller = "SaveTemplateController";
    config.backdrop = "static";
    open(config, thenCallback);
  }

  function confirmDelete(config, thenCallback) {
    config.size = "mobile-max";
    config.templateUrl = "app/modal/delete.modal.html";
    config.controller = "DeleteModalController";
    config.backdrop = "static";
    open(config, thenCallback);
  }

  function confirm(config, thenCallback) {
    config.size = "mobile-max";
    config.templateUrl = "app/modal/confirm.modal.html";
    config.controller = "ConfirmModalController";
    config.backdrop = "static";
    open(config, thenCallback);
  }

  function common(config, thenCallback) {
    config.size = "mobile-max";
    config.templateUrl = "app/modal/common.modal.html";
    config.controller = "CommonModalController";
    config.backdrop = "static";
    open(config, thenCallback);
  }

  function warning(config, thenCallback) {
    config.size = "mobile-max";
    config.templateUrl = "app/modal/warn.modal.html";
    config.controller = "CommonModalController";
    config.backdrop = "static";
    open(config, thenCallback);
  }

  function open(config, thenCallback) {
    $modal
      .open({
        templateUrl: config.templateUrl,
        controller: config.controller,
        controllerAs: "vm",
        backdrop: config.backdrop,
        keyboard: false,
        size: config.size,
        resolve: {
          config: function () {
            return config;
          },
        },
      })
      .result.then(
        function (data) {
          if (thenCallback) {
            thenCallback(data);
          }
        },
        function () {}
      );
  }

  return {
    error: error,
    success: success,
    uploadSlip: uploadSlip,
    selectTemplate: selectTemplate,
    saveTemplate: saveTemplate,
    confirmDelete: confirmDelete,
    warning: warning,
    common: common,
    menuPopup: menuPopup,
    homePopup: homePopup,
    formAddress: formAddress,
    selectAddress: selectAddress,
    confirm: confirm,
  };
}
