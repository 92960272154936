app.controller('AlertModalController', AlertModalController);

    AlertModalController.$inject = ['config', '$uibModalInstance'];

    function AlertModalController (config, $modalInstance) {
        var vm = this;

        vm.mode = config.mode;
        vm.text = config.text;
        
        vm.close = close;
        
        function close() {
        	$modalInstance.close();
        }
        
}
