angular.module("app").controller("SuccessModalController", SuccessModalController);

SuccessModalController.$inject = ['config', '$uibModalInstance'];

function SuccessModalController(config, $modalInstance) {
    var vm = this;
    vm.header = config.header;
    vm.content1 = config.content1;
    vm.content2 = config.content2;
    vm.content3 = config.content3;
    vm.close = close;

    function close() {
        $modalInstance.close();
    }
}