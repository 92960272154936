app.controller('MenuModalController', MenuModalController);

MenuModalController.$inject = ['config', '$uibModalInstance'];

    function MenuModalController (config, $modalInstance) {
        var vm = this;

        vm.data = config;
        
        vm.close = close;
        
        function close() {
        	$modalInstance.close();
        }
        
}
