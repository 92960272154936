angular.module("app").factory("SuccessModalFactory", SuccessModalFactory);

SuccessModalFactory.$inject = ['$uibModal'];

function SuccessModalFactory($uibModal) {

    function success(config, thenCallback) {
        open(config, thenCallback);
    }

    function open(config, thenCallback) {
        $uibModal.open({
            templateUrl: 'app/modal/success.modal.html',
            controller: 'SuccessModalController',
            controllerAs: 'vm',
            backdrop: 'static',
            keyboard: false,
            size: "lg",
            resolve: {
                config: function () {
                    return config;
                }
            }
        }).result.then(function () {
            if (thenCallback) {
                thenCallback();
            }
        });
    }

    return {
        success: success
    }
}