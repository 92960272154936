angular.module("app").controller("PurchaseOrderTemplateSelectorController", PurchaseOrderTemplateSelectorController);

PurchaseOrderTemplateSelectorController.$inject = ['$translate', '$state', '$stateParams', 'ModalFactory', '$sessionStorage', 'PoTemplateService', 'UserService', 'blockUI'];

function PurchaseOrderTemplateSelectorController($translate, $state, $stateParams, ModalFactory, $sessionStorage, PoTemplateService, UserService, blockUI) {

    var vm = this;
    var session = $sessionStorage;

    /*UI variable*/
    vm.navs = [];
    vm.userMenu = 'purchaseOrderTemplate';

    /*UI function*/
    vm.int = init();
    vm.back = back;
    vm.selectTemplate = selectTemplate;
    vm.deleteTemplate = deleteTemplate;

    function init() {
    	
    	vm.loadingPage = true;
    	
    	if (!session.user) {
			$state.go('app.home');
		}

        PoTemplateService.getTemplate($stateParams.poTemplateId, function (response) {
        	
        	vm.loadingPage = false;
        	
            if (response.responseCode == 0) {
                if (response.data.userId != session.user._id) {
                    $state.go('app.home');
                    return;
                } else {
                    vm.poTemplate = response.data;
                    vm.products = vm.poTemplate.items;
                    vm.summary = vm.poTemplate.summary;

                    $translate('menu.poTemplate').then(function (data) {
                        vm.navs[0] = { description: data, href: '/ecommerce/purchaseOrderTemplate' }
                        vm.navs[1] = { description: vm.poTemplate.name.substring(0, 30) + (vm.poTemplate.name.length > 30 ? ' ...' : '') }
                    });
                }
            } else if (response.responseCode == 13) {
                $translate(response.responseMsg).then(function (data) {
                    var config = {
                        header: data
                    }
                    ModalFactory.error(config, function () {
                        $state.go('app.purchaseOrderTemplate');
                    })
                })
            }
        })
    }


    function back() {
        $state.go('app.purchaseOrderTemplate');
    }

    function selectTemplate() {
        $translate('poTemplate.confirmSelect').then(function (data1) {
            $translate('poTemplate.confirmSelectContent').then(function (data2) {
                var config = {
                    header: data1,
                    content: data2
                }
                
                ModalFactory.common(config, function () {
                	blockUI.start();
                    PoTemplateService.updateCart($stateParams.poTemplateId, function (response) {
                    	blockUI.stop();
                        if (response.responseCode == 0) {
                            session.cart = response.data;
                            ModalFactory.selectTemplate({}, function () {
                                $state.go('app.cart');
                            })
                        }
                    })
                })
            });
        });
    }

    function deleteTemplate() {
        ModalFactory.confirmDelete({}, function () {
        	blockUI.start();
            PoTemplateService.deleteTemplate($stateParams.poTemplateId, function (response) {
            	blockUI.stop();
                if (response.responseCode == 0) {
                    $translate('deleteSuccess').then(function (data) {
                        var config = {
                            header: data
                        }
                        ModalFactory.success(config, function () {
                            $state.go('app.purchaseOrderTemplate');
                        })
                    });
                }
            })
        })
    }
}