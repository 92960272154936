angular.module("app").controller("ViewController", ViewController);

ViewController.$inject = [
  "$stateParams",
  "ProductService",
  "config",
  "$sessionStorage",
  "$uibModal",
];

function ViewController(
  $stateParams,
  ProductService,
  config,
  $sessionStorage,
  $modal
) {
  var vm = this;
  var session = $sessionStorage;
  var interval = null;

  /*UI variable*/
  vm.product = {};
  vm.config = config;
  vm.img = "";
  vm.navs = [];
  vm.criteria = {};
  vm.qty = 1;
  vm.loadingRecomended = false;

  /*UI function*/
  vm.init = init();
  vm.selectThumb = selectThumb;
  vm.loadRecomendedItems = loadRecomendedItems;
  vm.getUserLogin = getUserLogin;
  vm.qtyUpdate = qtyUpdate;
  vm.openLoginModal = openLoginModal;

  function init() {
    vm.loadingPage = true;

    ProductService.get($stateParams.id, function (response) {
      vm.product = response.data;

      if (vm.product.imageURLPaths.length == 0)
        vm.product.imageURLPaths.push(
          "https://www.raceentry.com/img/Race-Registration-Image-Not-Found.png"
        );
      vm.img = vm.product.imageURLPaths[0];
      vm.product.imageURLPath = vm.img;

      angular.forEach(session.categories, function (cat1) {
        if (vm.product.catId1 == cat1._id) {
          angular.forEach(cat1.subCategories, function (cat2) {
            if (vm.product.catId2 == cat2._id) {
              angular.forEach(cat2.subCategories, function (cat3) {
                if (vm.product.catId3 == cat3._id) {
                  vm.navs[0] = {
                    description: cat1.name,
                    href: "/ecommerce/product/inq/1/" + cat1._id,
                  };
                  vm.navs[1] = {
                    description: cat2.name,
                    href: "/ecommerce/product/inq/2/" + cat2._id,
                  };
                  vm.navs[2] = {
                    description: cat3.name,
                    href: "/ecommerce/product/inq/3/" + cat3._id,
                  };
                  vm.navs[3] = { description: vm.product.name };
                }
              });
            }
          });
        }
      });

      vm.loadingPage = false;

      vm.ps = [
        {
          name: "สินค้าที่คล้ายกัน",
          items: [],
        },
        {
          name: "สินค้าที่คุณอาจสนใจ",
          items: [],
        },
      ];

      vm.criteria = {
        page: 1,
        itemPerPage: 6,
        categoryId: vm.product.catId3,
        categoryLevel: 3,
        exceptItem: vm.product._id,
      };

      ProductService.getProducts(vm.criteria, function (response) {
        vm.ps[1].items = response.data;
        vm.ps[1].totalItems = response.totalItems;
        vm.ps[1].totalPage = response.totalPage;
        vm.ps[1].criteria = vm.criteria;
      });

      if (vm.product.productGroupOid > 0) {
        vm.criteria2 = {
          page: 1,
          itemPerPage: 12,
          exceptItem: vm.product._id,
          productGroupOid: vm.product.productGroupOid,
        };

        ProductService.getProducts(vm.criteria2, function (response2) {
          vm.ps[0].items = response2.data;
          vm.ps[0].totalItems = response2.totalItems;
          vm.ps[0].totalPage = response2.totalPage;
          vm.ps[0].criteria = vm.criteria2;
        });
      }
    });
  }

  function selectThumb(imgPath) {
    vm.product.imageURLPath = imgPath;
    vm.img = imgPath;
  }

  function loadRecomendedItems(item) {
    // if (page == 0 || page > vm.totalPage) {
    //   page = vm.criteria.page;
    // } else {
    //   vm.loadingRecomended = true;

    //   vm.criteria.page = page

    //   ProductService.getProducts(vm.criteria, function (response) {
    //     vm.products3 = response.data;
    //     vm.totalItems = response.totalItems;
    //     vm.totalPage = response.totalPage;
    //     vm.loadingRecomended = false;
    //   });
    // }

    item.criteria.page++;

    item.loading = true;

    if (item.criteria.page <= item.totalPage) {
      ProductService.getProducts(item.criteria, function (response2) {
        let datas = response2.data;
        item.loading = false;
        for (let index = 0; index < datas.length; index++) {
          const element = datas[index];
          item.items.push(element);
        }
      });
    }
  }

  function qtyUpdate(qty) {
    vm.qty = vm.qty + qty;

    if (vm.qty == 0) {
      vm.qty = 1;
    } else if (vm.qty == 1000) {
      vm.qty = 999;
    }
  }

  function openLoginModal() {
    $modal
      .open({
        templateUrl: "app/user/login.html",
        controller: "LoginController",
        controllerAs: "vm",
        keyboard: false,
        size: "lg",
        resolve: {
          config: function () {
            return {
              mode: "",
              text: "",
            };
          },
        },
      })
      .result.then(
        function () {
          // $state.go('app.home');
          location.reload();
        },
        function () {}
      );
  }

  function getUserLogin() {
    return session.user;
  }

  $("#zoom").hover(function () {
    // Initiate magnification powers
    $("#zoom").magnify({});
  });
}
