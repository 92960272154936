(function () {
  angular
    .module("app")
    .controller("RegisterSelectorController", RegisterSelectorController);

  RegisterSelectorController.$inject = [
    "$stateParams",
    "$state",
    "UserService",
    "ProvinceService",
    "SuccessModalFactory",
    "ModalFactory",
    "$translate",
    "blockUI",
    "animatedScroll",
  ];

  function RegisterSelectorController(
    $stateParams,
    $state,
    UserService,
    ProvinceService,
    SuccessModalFactory,
    ModalFactory,
    $translate,
    blockUI,
    animatedScroll
  ) {
    var vm = this;
    /*UI variable*/
    vm.registerCustomerType = {
      type: "",
      text: "",
    };
    vm.cust = {}; // object save customer
    vm.fileList = [];
    vm.acceptFileUploadType = ".pdf,.jpg,.png,.doc,.docx";
    vm.responseMsg = null;
    vm.navs = [];

    /*UI function*/
    vm.init = init();
    vm.back = back;
    vm.submit = submit;
    vm.getBirthDateDDRange = getBirthDateDDRange;
    vm.getBirthDateYYYYRange = getBirthDateYYYYRange;
    vm.openUpload = openUpload;
    vm.getFileUploadFile = getFileUploadFile;
    vm.reset = reset;
    vm.setDrity = setDrity;
    vm.substring = substring;

    function init() {
      vm.loadingPage = true;

      reset("I");
      vm.registerCustomerType.type =
        $stateParams.type == "oldCustomer" ? true : false; //old(true) or new(false)
      vm.registerCustomerType.text = "register." + $stateParams.type;

      getBirthDateDDRange(1, new Date().getFullYear());
      var fileUpload = document.getElementById("upload1");
      fileUpload.setAttribute("accept", vm.acceptFileUploadType);
      var fileUpload = document.getElementById("upload2");
      fileUpload.setAttribute("accept", vm.acceptFileUploadType);

      ProvinceService.getProvince(function (response) {
        vm.provinces = response.data;
        vm.loadingPage = false;
      });

      $translate("menu.register").then(function (data) {
        vm.navs[0] = { description: data, href: "/ecommerce/register" };
      });

      vm.navs[1] = { description: "สมัครตัวแทนจำหน่าย" };
    }

    function back() {
      $state.go("app.register");
    }

    function getBirthDateDDRange(month, year) {
      var dateArr = [],
        numOfDay = new Date(year, month, 0).getDate();
      if (month == undefined || year == undefined) numOfDay = 31;
      for (var i = 1; i <= numOfDay; i++) {
        dateArr.push(i);
      }
      return dateArr;
    }

    function getBirthDateYYYYRange() {
      var yearArr = [],
        nowYear = new Date().getFullYear();
      for (var i = 0; i < 70; i++) {
        yearArr.push(nowYear - i);
      }
      return yearArr;
    }

    function openUpload(id) {
      document.getElementById(id).click();
    }

    function getFileUploadFile(file, id) {
      if (file.length > 0) {
        var filesize = (file[0].size / 1048576).toFixed(2); // size MB
        if (filesize > 10) {
          $translate("err.user.register.fileMoreThanTenMb").then(function (
            data
          ) {
            var config = {
              header: data,
            };
            ModalFactory.error(config, function () {
              id == "upload1" ? (vm.file1 = undefined) : (vm.file2 = undefined);
            });
          });
        } else {
          var fileType = vm.acceptFileUploadType.split(",");
          var accept = false;

          for (var i = 0; i < fileType.length; i++) {
            if (
              "." + file[0].name.split(".").pop().toLowerCase() ==
              fileType[i]
            ) {
              accept = true;
              break;
            }
          }
          if (!accept) {
            $translate("err.user.register.fileNotAllow.header").then(function (
              data1
            ) {
              $translate("err.user.register.fileNotAllow.content1").then(
                function (data2) {
                  var config = {
                    header:
                      data1 +
                      " ." +
                      file[0].name.split(".").pop().toLowerCase(),
                    content1: data2 + vm.acceptFileUploadType,
                  };
                  ModalFactory.error(config, function () {
                    id == "upload1"
                      ? (vm.file1 = undefined)
                      : (vm.file2 = undefined);
                  });
                }
              );
            });
          }
        }
      }
    }

    function reset(customerType) {
      vm.cust = {
        dealerId: "",
        title: "",
        firstName: "",
        lastName: "",
        sex: "Male",
        birthDateDD: null,
        birthDateMM: null,
        birthDateYYYY: null,
        mobile: "",
        tel: "",
        email: "",
        province: "",
        file1: null,
        file2: null,
        password: "",
        status: 0,
        priceLevel: 4,
        objLog: {
          system: "ysc-ecommerce",
          createdDate: new Date(),
          updatedDate: new Date(),
        },
      };
      vm.cust.customerType = customerType;

      //for test
      vm.cust.status = 1;
      vm.cust.password = 1;
    }

    function setDrity() {
      var form = vm.userForm;

      if (form.$invalid) {
        angular.forEach(form.$error, function (controls, errorName) {
          angular.forEach(controls, function (control) {
            control.$setDirty();
          });
        });
      }
    }

    function submit() {
      if (vm.captcha1) {
        blockUI.start("Please wait...");
        vm.cust.file1 = vm.file1;
        vm.cust.file2 = vm.file2;

        if (vm.cust.customerType == "C") {
          vm.cust.documentId = vm.cust.taxId;
        }

        UserService.createUser(vm.cust, function (response) {
          blockUI.stop();

          if (response.responseCode == 14) {
            //email already exists in the serve
            $translate(response.responseMsg).then(function (data) {
              vm.responseMsg = data;
              animatedScroll.scroll("#error-msg");
            });
          } else if (response.responseCode == 0) {
            //success

            var config = {
              header: "",
              content1: "",
              content2: "",
              content3: "",
            };
            $translate("success.user.register.header").then(function (data1) {
              $translate("success.user.register.content1").then(function (
                data2
              ) {
                $translate("success.user.register.content2").then(function (
                  data3
                ) {
                  $translate("success.user.register.content3").then(function (
                    data4
                  ) {
                    config.header = data1;
                    config.content1 = data2;
                    config.content2 = data3;
                    config.content3 = data4;

                    SuccessModalFactory.success(config, function () {
                      $state.go("app.home");
                    });
                  });
                });
              });
            });
          }
        });
      }
    }

    function substring(string) {
      if (string == undefined) return;
      if (string.length > 20) {
        return [
          string.substring(0, 15) + " ... ." + string.split(".").pop(),
          string.split(".").pop().toLowerCase(),
        ];
      } else {
        return [string, string.split(".").pop().toLowerCase()];
      }
    }
  }
})();
