(function () {
	angular.module('app').controller('RegisterController', RegisterController);


	RegisterController.$inject = ['$log', '$translate', '$cookies', '$state'];

	function RegisterController($log, $translate, $cookies, $state) {

		var vm = this;

		/*UI variable*/
		vm.navs = [];

		/*UI function*/
		vm.init = init();
    vm.registorSelector = registorSelector;
    vm.registorMember = registorMember;

		function init() {
			$translate('menu.register').then(function (data) {
				vm.navs[0] = { description: data}
			});
		}

		function registorSelector(type) {
			$state.go('app.registerselector', { type: type });
    }

    function registorMember() {
			$state.go('app.registerMember');
		}
	}
})();
