angular.module("app").controller("InvoiceController", InvoiceController);

InvoiceController.$inject = [
  "$translate",
  "$state",
  "DateTimepickerFactory",
  "InvoiceService",
  "PagingService",
  "$sessionStorage",
  "Constant",
  "UserService",
  "ModalFactory",
];

function InvoiceController(
  $translate,
  $state,
  DateTimepickerFactory,
  InvoiceService,
  PagingService,
  $sessionStorage,
  Constant,
  UserService,
  ModalFactory
) {
  var vm = this;
  var session = $sessionStorage;

  /*UI variable*/
  vm.search = {};
  vm.criteriaType = 1; // 1 = cureent month, 2 = old month, 3 from date - to date
  vm.oldMonth = []; //เดือนปัจจุบันย้อนหลังไป 12 เดือน
  vm.fromDate = new Date();
  vm.toDate = new Date();
  vm.criteria = {};
  vm.navs = [];
  vm.userMenu = "invoice";
  vm.user = session.user;

  vm.invoices = [];

  /*UI function*/
  vm.int = init();
  vm.submitSearch = submitSearch;
  vm.selectedPage = selectedPage;
  vm.selector = selector;
  vm.changeCriteriaType = changeCriteriaType;
  vm.payment = payment;

  function init() {
    vm.loadingPage = true;

    if (!session.user) {
      $state.go("app.home");
    }

    vm.criteria = {
      itemPerPage: 20,
      fromDate: new Date(),
      toDate: new Date(),
      page: 1,
      userId: session.user._id,
      custCode: session.user.dealerId,
    };

    $translate("menu.invoice").then(function (data) {
      vm.navs[0] = { description: data };
    });

    Constant.invoiceStatus(function (result) {
      vm.status = result;
    });

    setOldMonth();
    DateTimepickerFactory.initialDatePicker(vm);
    submitSearch();
  }

  function selectedPage(page) {
    vm.criteria.page = page;
    submitSearch();
  }

  function changeCriteriaType(id) {
    vm.criteriaType = id;
    vm.criteria.page = 1;
  }

  function selector(invoiceNo) {
    $state.go("app.invoiceSelector", { invoiceNo: invoiceNo });
  }

  function payment() {
    session.invoices = [];

    angular.forEach(vm.invoices, function (invoice) {
      if (invoice.checked) {
        session.invoices.push("invoice|" + invoice._id);
      }
    });

    if (session.invoices.length > 0) {
      $state.go("app.payment");
    } else {
      $translate("err.payment.noitem.header").then(function (header) {
        $translate("err.payment.noitem.detail").then(function (detail) {
          var config = {
            header: header,
            content1: detail,
          };
          ModalFactory.error(config, function (callback) {});
        });
      });
    }
  }

  function setOldMonth() {
    var currentDate = new Date();
    currentDate.setDate(1);
    for (var i = 0; i < 12; i++) {
      var month = currentDate.getMonth() - i;
      vm.oldMonth.push({
        value:
          (month > 0 ? month : month + 12) +
          "/" +
          "1/" +
          (month > 0
            ? currentDate.getFullYear()
            : currentDate.getFullYear() - 1),
        month: "months." + (month > 0 ? month : month + 12),
        year:
          month > 0 ? currentDate.getFullYear() : currentDate.getFullYear() - 1,
      });
    }
    vm.search.oldMonth = vm.oldMonth[0].value;
  }

  function submitSearch() {
    vm.contentLoad = true;

    if (vm.criteriaType == 1) {
      var date = new Date();
      date.setHours(0, 0, 0, 0);
      vm.criteria.fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
      vm.criteria.toDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    } else if (vm.criteriaType == 2) {
      var date = new Date(vm.search.oldMonth);
      vm.criteria.fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
      vm.criteria.toDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    } else {
      vm.criteria.fromDate = vm.fromDate;
      vm.criteria.toDate = vm.toDate;
    }

    vm.criteria.toDate.setHours(23, 59, 59);
    InvoiceService.search(vm.criteria, function (response) {
      if (response.responseCode == 0) {
        vm.invoices = response.data;
        PagingService.get(
          vm.criteria.page,
          vm.criteria.itemPerPage,
          response.totalItems,
          function (response) {
            vm.paging = response;
            vm.contentLoad = vm.loadingPage = false;
          }
        );
      } else {
        vm.contentLoad = vm.loadingPage = false;
      }
    });
  }
}
