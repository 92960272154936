app.factory('JobService', JobService);

JobService.$inject = [ '$resource', 'CommonCallback', 'config' ];

function JobService($resource, CommonCallback, config) {
	
	var resource = $resource('', {}, {
			'gets': { 
	        	method: 'GET',
	        	isArray: false,
	        	url: config.apiUrl+'/job'
	        }
	    });
		
	function gets( successCallBack) {
		resource.gets({
		}, 
		CommonCallback.onSuccess(successCallBack), 
		CommonCallback.onError);
	}
	
		
		
		
	    return {
	    	gets: gets
	    };
}
