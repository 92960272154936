(function () {
  angular.module("app").service("UserService", UserService);

  UserService.$inject = [
    "$resource",
    "CommonCallback",
    "config",
    "$sessionStorage",
    "$translate",
    "ModalFactory",
    "$state",
  ];

  function UserService(
    $resource,
    CommonCallback,
    config,
    $sessionStorage,
    $translate,
    ModalFactory,
    $state
  ) {
    var resource = $resource(
      "",
      {},
      {
        getUser: {
          method: "GET",
          url: config.apiUrl + "/user/:userId",
        },
        checkUserHavePassword: {
          method: "GET",
          url: config.apiUrl + "/checkUserHavePassword/:key",
        },
        createUser: {
          method: "POST",
          headers: { "Content-Type": undefined },
          url: config.apiUrl + "/user",
        },
        createMember: {
          method: "POST",
          headers: { "Content-Type": undefined },
          url: config.apiUrl + "/user/createMember",
        },
        updateAddress: {
          method: "POST",
          url: config.apiUrl + "/user/updateAddress",
        },
        createAddress: {
          method: "POST",
          url: config.apiUrl + "/user/createAddress",
        },
        removeAddress: {
          method: "POST",
          url: config.apiUrl + "/user/removeAddress",
        },
        getAddress: {
          method: "POST",
          url: config.apiUrl + "/user/getAddress",
        },
        getAddresses: {
          method: "POST",
          url: config.apiUrl + "/user/getAddresses",
        },
        login: {
          method: "POST",
          url: config.apiUrl + "/login",
        },
        wishlist: {
          method: "POST",
          url: config.apiUrl + "/wishlist",
        },
        changePassword: {
          method: "POST",
          url: config.apiUrl + "/user/changePassword",
        },
        reqResetPassword: {
          method: "POST",
          url: config.apiUrl + "/requestResetPassword",
        },
        verifyKey: {
          method: "GET",
          url: config.apiUrl + "/resetpassword/:key",
        },
        resetPassword: {
          method: "POST",
          url: config.apiUrl + "/resetpassword",
        },
      }
    );

    function getUser(userId, successCallBack) {
      resource.getUser(
        {
          userId: userId,
        },
        CommonCallback.onSuccess(successCallBack),
        CommonCallback.onError
      );
    }

    function checkUserHavePassword(key, successCallBack) {
      resource.checkUserHavePassword(
        {
          key: key,
        },
        CommonCallback.onSuccess(successCallBack),
        CommonCallback.onError
      );
    }

    function createUser(user, successCallBack) {
      var fd = new FormData();
      fd.append("file1", user.file1);
      fd.append("file2", user.file2);
      user.file1 = "";
      user.file2 = "";
      fd.append("data", JSON.stringify(user));

      resource.createUser(
        fd,
        CommonCallback.onSuccess(successCallBack),
        CommonCallback.onError
      );
    }

    function createMember(user, successCallBack) {
      var fd = new FormData();
      fd.append("data", JSON.stringify(user));

      resource.createMember(
        fd,
        CommonCallback.onSuccess(successCallBack),
        CommonCallback.onError
      );
    }

    function login(user, successCallBack) {
      resource.login(
        user,
        CommonCallback.onSuccess(successCallBack),
        CommonCallback.onError
      );
    }

    function wishlist(email, productId, successCallBack) {
      resource.wishlist(
        {
          email: email,
          productId: productId,
        },
        CommonCallback.onSuccess(successCallBack),
        CommonCallback.onError
      );
    }

    function isUserLogon(callback) {
      var session = $sessionStorage;

      if (!session.user) {
        $translate(["err.403.header", "err.403.content1"]).then(function (
          translate
        ) {
          var config = {};
          config.header = translate["err.403.header"];
          config.content1 = translate["err.403.content1"];
          ModalFactory.error(config, function (result) {
            $state.go("app.home");
          });
        });

        callback(false);
      } else {
        callback(true);
      }
    }

    function updateAddress(data, successCallBack) {
      resource.updateAddress(
        data,
        CommonCallback.onSuccess(successCallBack),
        CommonCallback.onError
      );
    }

    function createAddress(user, successCallBack) {
      resource.createAddress(
        user,
        CommonCallback.onSuccess(successCallBack),
        CommonCallback.onError
      );
    }

    function removeAddress(user, successCallBack) {
      resource.removeAddress(
        user,
        CommonCallback.onSuccess(successCallBack),
        CommonCallback.onError
      );
    }

    function getAddress(data, successCallBack) {
      resource.getAddress(
        data,
        CommonCallback.onSuccess(successCallBack),
        CommonCallback.onError
      );
    }

    function getAddresses(id, successCallBack) {
      let data = {
        _id: id,
      };
      resource.getAddresses(
        data,
        CommonCallback.onSuccess(successCallBack),
        CommonCallback.onError
      );
    }

    function changePassword(user, successCallBack) {
      resource.changePassword(
        user,
        CommonCallback.onSuccess(successCallBack),
        CommonCallback.onError
      );
    }

    function reqResetPassword(userId, successCallBack) {
      resource.reqResetPassword(
        {
          userId: userId,
        },
        CommonCallback.onSuccess(successCallBack),
        CommonCallback.onError
      );
    }

    function resetPassword(key, password, successCallBack) {
      resource.resetPassword(
        {
          key: key,
          newPassword: password,
        },
        CommonCallback.onSuccess(successCallBack),
        CommonCallback.onError
      );
    }

    function verifyKey(key, successCallBack) {
      resource.verifyKey(
        {
          key: key,
        },
        CommonCallback.onSuccess(successCallBack),
        CommonCallback.onError
      );
    }

    return {
      getUser: getUser,
      checkUserHavePassword: checkUserHavePassword,
      createUser: createUser,
      login: login,
      wishlist: wishlist,
      isUserLogon: isUserLogon,
      changePassword: changePassword,
      resetPassword: resetPassword,
      reqResetPassword: reqResetPassword,
      verifyKey: verifyKey,
      createMember: createMember,
      updateAddress: updateAddress,
      createAddress: createAddress,
      getAddress: getAddress,
      removeAddress: removeAddress,
      getAddresses: getAddresses,
    };
  }
})();
