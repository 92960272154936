angular
  .module("app")
  .controller("SelectAddressModalController", SelectAddressModalController);

SelectAddressModalController.$inject = [
  "config",
  "$uibModalInstance",
  "ModalFactory",
];

function SelectAddressModalController(config, $modalInstance, ModalFactory) {
  var vm = this;

  //UI Variable
  vm.hour = [];
  vm.minute = [];
  vm.slip = {};
  vm.banks = [];

  vm.header = "ข้อมูลที่อยู่";

  vm.addresses = [];

  //UI Function
  vm.init = init();
  vm.cancel = cancel;
  vm.createNewAddress = createNewAddress;
  vm.select = select;

  function init() {
    vm.header = config.header;
    vm.addresses = config.addresses;
  }

  function cancel() {
    $modalInstance.dismiss("");
  }

  function select() {
    $modalInstance.close(vm.selectItem);
  }

  function createNewAddress() {
    $modalInstance.close(undefined);
  }
}
