angular.module("app").factory("DateTimepickerFactory", DateTimepickerFactory);

DateTimepickerFactory.$inject = [];

function DateTimepickerFactory() {
  function initialDatePicker(e) {
    e.format = "dd/MM/yyyy";
    (e.today = function () {
      e.dt = new Date();
    }),
      e.today(),
      (e.clear = function () {
        e.dt = null;
      }),
      (e.disabled = function (e, t) {
        // return "day" === t && (0 === e.getDay() || 6 === e.getDay())
      }),
      (e.toggleMin = function () {
        // e.minDate = e.minDate ? null : new Date
      }),
      e.toggleMin(),
      (e.maxDate = new Date(2020, 5, 22)),
      (e.open1 = function () {
        e.popup1.opened = !0;
      }),
      (e.open2 = function () {
        e.popup2.opened = !0;
      }),
      (e.setDate = function (t, a, n) {
        e.dt = new Date(t, a, n);
      }),
      (e.dateOptions = {
        formatYear: "yy",
        startingDay: 1,
        showWeeks: false,
      }),
      (e.formats = ["dd-MMMM-yyyy", "yyyy/MM/dd", "dd.MM.yyyy", "shortDate"]),
      (e.altInputFormats = ["M!/d!/yyyy"]),
      (e.popup1 = {
        opened: !1,
      }),
      (e.popup2 = {
        opened: !1,
      });
    var t = new Date();
    t.setDate(t.getDate() + 1);
    var a = new Date();
    a.setDate(t.getDate() + 1),
      (e.events = [
        {
          date: t,
          status: "full",
        },
        {
          date: a,
          status: "partially",
        },
      ]),
      (e.getDayClass = function (t, a) {
        if ("day" === a)
          for (
            var n = new Date(t).setHours(0, 0, 0, 0), o = 0;
            o < e.events.length;
            o++
          ) {
            var i = new Date(e.events[o].date).setHours(0, 0, 0, 0);
            if (n === i) return e.events[o].status;
          }
        return "";
      });
  }

  function initialTimePicker(e) {
    (e.mytime = new Date()),
      (e.hstep = 1),
      (e.mstep = 15),
      (e.options = {
        hstep: [1, 2, 3],
        mstep: [1, 5, 10, 15, 25, 30],
      }),
      (e.ismeridian = !0),
      (e.toggleMode = function () {
        return (e.ismeridian = !e.ismeridian);
      }),
      (e.update = function () {
        var t;
        return (
          (t = new Date()), t.setHours(14), t.setMinutes(0), (e.mytime = t)
        );
      }),
      (e.changed = function () {}),
      (e.clear = function () {
        return (e.mytime = null);
      });
  }

  return {
    initialDatePicker: initialDatePicker,
    initialTimePicker: initialTimePicker,
  };
}
