angular.module("app").controller("InqController", InqController);

InqController.$inject = [
  "$log",
  "$translate",
  "$stateParams",
  "ProductService",
  "PagingService",
  "$sessionStorage",
  "ProductCategoryService",
  "$uibModal",
  "BrandService",
];

function InqController(
  $log,
  $translate,
  $stateParams,
  ProductService,
  PagingService,
  $sessionStorage,
  ProductCategoryService,
  $modal,
  BrandService
) {
  var vm = this;
  var session = $sessionStorage;

  /*UI variable*/
  vm.colmd = 15; //col-md-4 for disply items
  vm.navs = [];
  vm.categoryOid;
  vm.categoryLevel;
  vm.categorySelected = {};
  vm.products = [];
  vm.totalItems = 0;
  vm.totalPage = 0;
  vm.criteria = {};
  vm.categoryMenus = {};
  vm.brandLists;

  vm.paging = {};

  vm.cat3Lists = [];

  /*UI function*/
  vm.init = init();
  vm.selectedPage = selectedPage;
  vm.openBrandPopup = openBrandPopup;
  vm.selectBrand = selectBrand;

  vm.selectCat2 = selectCat2;

  function init() {
    vm.loadingPage = true;
    vm.categoryOid = $stateParams.id;
    vm.categoryLevel = $stateParams.level;

    angular.forEach(session.categories, function (cat1) {
      if (vm.categoryLevel > 1) {
        angular.forEach(cat1.subCategories, function (cat2) {
          if (vm.categoryLevel > 2) {
            angular.forEach(cat2.subCategories, function (cat3) {
              if (cat3._id == vm.categoryOid) {
                vm.categorySelected.cat1 = { name: cat1.name, _id: cat1._id };
                vm.categorySelected.cat2 = { name: cat2.name, _id: cat2._id };
                vm.categorySelected.cat3 = { name: cat3.name, _id: cat3._id };
                vm.categoryMenus = cat1;
              }
            });
          } else {
            if (cat2._id == vm.categoryOid) {
              vm.categorySelected.cat1 = { name: cat1.name, _id: cat1._id };
              vm.categorySelected.cat2 = { name: cat2.name, _id: cat2._id };
              vm.categoryMenus = cat1;
            }
          }
        });
      } else {
        if (cat1._id == vm.categoryOid) {
          vm.categorySelected.cat1 = { name: cat1.name, _id: cat1._id };
          vm.categoryMenus = cat1;
        }
      }
    });

    if (
      vm.categorySelected.cat1 &&
      vm.categorySelected.cat2 &&
      vm.categorySelected.cat3
    ) {
      vm.navs[0] = {
        description: vm.categorySelected.cat1.name,
        href: "/ecommerce/product/inq/1/" + vm.categorySelected.cat1._id,
      };
      vm.navs[1] = {
        description: vm.categorySelected.cat2.name,
        href: "/ecommerce/product/inq/2/" + vm.categorySelected.cat2._id,
      };
      vm.navs[2] = { description: vm.categorySelected.cat3.name };
    } else if (
      vm.categorySelected.cat1 &&
      vm.categorySelected.cat2 &&
      !vm.categorySelected.cat3
    ) {
      vm.navs[0] = {
        description: vm.categorySelected.cat1.name,
        href: "/ecommerce/product/inq/1/" + vm.categorySelected.cat1._id,
      };
      vm.navs[1] = { description: vm.categorySelected.cat2.name };
    } else {
      vm.navs[0] = { description: vm.categorySelected.cat1.name };
    }

    vm.criteria = {
      page: 1,
      itemPerPage: 15,
      order: 1,
      categoryId: vm.categoryOid,
      categoryLevel: vm.categoryLevel,
    };

    if ($stateParams.brandOid) {
      vm.criteria.brandIds = [];
      vm.criteria.brandIds.push($stateParams.brandOid);
      vm.selectedBrand = $stateParams.brandOid;
    }

    searchWithBrand();

    var width = window.innerWidth > 0 ? window.innerWidth : screen.width;

    if (width > 768) {
      $("#mobileMenux").addClass("in");
    } else {
      $("#mobileMenux").removeClass("in");
    }
  }

  function selectedPage(page) {
    vm.criteria.page = page;
    search();
  }

  function searchWithBrand() {
    vm.loadingItem = true;

    vm.criteria.p = 5;

    if ($sessionStorage.user) {
      vm.criteria.p = $sessionStorage.user.priceLevel;
    }

    ProductService.getProducts(vm.criteria, function (response) {
      //Inquiry Product Groups
      vm.products = response.data;
      vm.totalItems = response.totalItems;
      vm.totalPage = response.totalPage;
      vm.brandLists = response.brands;

      if (vm.brandLists) {
        let brands = [];

        for (let index = 0; index < vm.brandLists.length; index++) {
          const element = vm.brandLists[index];
          if (element.brandId) {
            brands.push(element.brandId);
          }
        }

        BrandService.gets(brands, function (brandsresponse) {
          vm.brandLists = brandsresponse.data;
        });
      }

      PagingService.get(
        vm.criteria.page,
        vm.criteria.itemPerPage,
        response.totalItems,
        function (response) {
          //Inquiry Product Groups
          vm.paging = response;
          vm.loadingItem = false;
          vm.loadingPage = false;
        }
      );
    });
  }

  function search() {
    vm.loadingItem = true;

    vm.criteria.p = 5;

    if ($sessionStorage.user) {
      vm.criteria.p = $sessionStorage.user.priceLevel;
    }

    ProductService.getProducts(vm.criteria, function (response) {
      //Inquiry Product Groups
      vm.products = response.data;
      vm.totalItems = response.totalItems;
      vm.totalPage = response.totalPage;

      PagingService.get(
        vm.criteria.page,
        vm.criteria.itemPerPage,
        response.totalItems,
        function (response) {
          //Inquiry Product Groups
          vm.paging = response;
          vm.loadingItem = false;
          vm.loadingPage = false;
        }
      );
    });
  }

  function openBrandPopup() {
    var option = {
      templateUrl: "app/product/partial/brandFilterPopup.html",
      controller: "BrandFilterPopupController",
      controllerAs: "vm",
      backdrop: "static",
      size: "mobile-max",
      keyboard: false,
      resolve: {
        config: function () {
          return {
            brandLists: vm.brandLists,
            selectedBrands: vm.selectedBrands,
          };
        },
      },
    };

    $modal.open(option).result.then(
      function (selectedBrands) {
        // Cancel Function
        vm.selectedBrands = selectedBrands;

        vm.criteria.brandIds = [];

        angular.forEach(vm.selectedBrands, function (brand) {
          vm.criteria.brandIds.push(brand.id);
        });

        search();
      },
      function (ex) {
        // Dismiss Function
      }
    );
  }

  function selectBrand(brand) {
    // vm.selectBrand = brand.brandOid;
    vm.criteria.brandIds = [];
    vm.criteria.brandIds.push(brand.brandOid);
    search();
  }

  function selectCat2(cat2) {
    vm.cat3Lists = [];
    if (cat2 != null) {
      vm.cat3Lists = cat2.subCategories;
      if (!vm.categorySelected.cat2) {
        vm.categorySelected.cat2 = {
          _id: cat2._id,
          name: cat2.name,
        };
      } else {
        vm.categorySelected.cat2._id = cat2._id;
        vm.categorySelected.cat2.name = cat2.name;
      }
    }
  }
}
