angular.module("app").controller("CommonModalController", CommonModalController);

CommonModalController.$inject = ['config', '$uibModalInstance'];

function CommonModalController(config, $modalInstance){
    var vm = this;

    vm.header = config.header;
    vm.content = config.content;
    
    vm.ok = ok;
    vm.cancel = cancel;

    function ok(state) {
        $modalInstance.close();
    }

    function cancel(){
        $modalInstance.dismiss("");
    }
}