angular.module("app").controller('SaveTemplateController', SaveTemplateController);

SaveTemplateController.$inject = ['$uibModalInstance', 'ModalFactory', '$sessionStorage', 'PoTemplateService', '$translate', 'focus', 'ConfigService'];

function SaveTemplateController($modalInstance, ModalFactory, $sessionStorage, PoTemplateService, $translate, focus, ConfigService) {

    var vm = this;
    var session = $sessionStorage;

    //UI Variable
    vm.template = {
        name: "",
        description: "",
        items: [],
        userId: session.user._id,
        objLog: {
            system: 'ysc-ecommerce',
            createdDate: new Date(),
            updatedDate: new Date()
        },
        summary: {

        }
    };
    // moment().hour(8).minute(0).second(0).toDate();
    //UI function
    vm.init = init();
    vm.close = close;
    vm.setDirty = setDirty;
    vm.save = save;

    function init() {
    	ConfigService.get(function(response){
			vm.configData = response.data;
			focus('templateName');
		});
        
    }

    function close() {
        $modalInstance.close();
    }

    function setDirty() {
        var form = vm.userForm;
        if (form.$invalid) {
            angular.forEach(form.$error, function (controls, errorName) {
                angular.forEach(controls, function (control) {
                    control.$setDirty();
                });
            });
        }
    }

    function save() {
    	
    	vm.responseMsg = null;

        vm.products = session.cart.products;
        vm.template.items = [];
        vm.template.summary = session.cart.summary;
        
        var useNormalPrice = false;
        
        if( session.user.creditType == 'Cash' && session.user.priceLevel == 4 && (vm.configData.priceForUpgradeLevel.value > session.cart.summary.totalNormalPrice ) ){
        	useNormalPrice = true;
        }
        
        for (var i = 0; i < vm.products.length; i++) {
            vm.template.items.push({
                _id: vm.products[i]._id,
                productOid: vm.products[i].productOid,
                qty: vm.products[i].qty,
                memberPrice: useNormalPrice?vm.products[i].normalPrice:vm.products[i].memberPrice
            });
        }

        PoTemplateService.createTemplate(vm.template, function (response) {
            if (response.responseCode == 0) {
                $translate('success.poTemplate.saveSuccess').then(function (data) {
                    var config = { header: data };
                    ModalFactory.success(config, function () {
                        close();
                    })
                });
            }else{
            	if (response.responseCode == 501) {
            		$translate(response.responseMsg).then(function (data) {
                		vm.responseMsg = data;
                	});
            	}
            }
        })
    }
}