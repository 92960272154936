app.factory("POService", POService);

POService.$inject = [
  "$resource",
  "CommonCallback",
  "config",
  "$sessionStorage",
];

function POService($resource, CommonCallback, config, $sessionStorage) {
  function getUserId() {
    var userId = undefined;
    if ($sessionStorage.user) {
      userId = $sessionStorage.user._id;
    }

    return userId;
  }

  var resource = $resource(
    "",
    {},
    {
      get: {
        method: "GET",
        url: config.apiUrl + "/po/:id",
      },
      create: {
        method: "POST",
        url: config.apiUrl + "/po",
      },
      search: {
        method: "POST",
        url: config.apiUrl + "/po/search",
      },
    }
  );

  function get(id, successCallBack) {
    resource.get(
      {
        id: id,
      },
      CommonCallback.onSuccess(successCallBack),
      CommonCallback.onError
    );
  }

  function create(data, successCallBack) {
    let datas = {
      // name: data.name,
      // line1: data.line1,
      // line2: data.line2,
      // province: data.province,
      // postcode: data.postcode,
      // tel: data.tel,
      delivery: data.delivery,
      billing: data.billing,
      cod: data.cod,
      partialInvoice: data.partialInvoice,
      remark: data.remark,
      deliveryType: data.deliveryType,
      userId: getUserId(),
    };

    resource.create(
      datas,
      CommonCallback.onSuccess(successCallBack),
      CommonCallback.onError
    );
  }

  function search(data, successCallBack) {
    resource.search(
      data,
      CommonCallback.onSuccess(successCallBack),
      CommonCallback.onError
    );
  }

  return {
    create: create,
    get: get,
    search: search,
  };
}
