angular.module("app").controller("CartController", CartController);

CartController.$inject = [
  "$translate",
  "$state",
  "CartService",
  "$sessionStorage",
  "$uibModal",
  "ModalFactory",
  "blockUI",
];

function CartController(
  $translate,
  $state,
  CartService,
  $sessionStorage,
  $modal,
  ModalFactory,
  blockUI
) {
  var vm = this;
  var session = $sessionStorage;

  /*UI variable*/
  vm.cart = {};
  vm.summary = {};
  vm.products = [];

  /* Nav Bar Setup */
  vm.navs = [];

  /*UI function*/
  vm.init = init();
  vm.minus = minus;
  vm.plus = plus;
  vm.calculate = calculate;
  vm.deleteItem = deleteItem;
  vm.shipment = shipment;
  vm.saveTemplate = saveTemplate;
  vm.prepareEditQty = prepareEditQty;
  vm.editQty = editQty;

  function init() {
    vm.loadingPage = true;

    if (!session.user) {
      $state.go("app.home");
    }

    $translate("menu.cart").then(function (data) {
      vm.navs[0] = { description: data };
    });

    calculate();

    if (
      session.user.creditLimit == 0 &&
      session.user.creditType == "Cash" &&
      session.user.priceLevel == 4
    ) {
      $translate("warn.cart.pricelv4.header").then(function (header) {
        $translate("warn.cart.pricelv4.detail").then(function (detail) {
          var config = {
            header: header,
            content: detail,
          };
          ModalFactory.warning(config, function (callback) {});
        });
      });
    }

    if (
      session.user.creditLimit == 0 &&
      session.user.creditType == "Cash" &&
      session.user.priceLevel == 5
    ) {
      $translate("warn.cart.pricelv5.header").then(function (header) {
        $translate("warn.cart.pricelv5.detail").then(function (detail) {
          var config = {
            header: header,
            content: detail,
          };
          ModalFactory.warning(config, function (callback) {});
        });
      });
    }
  }

  function minus(item) {
    if (item.qty > 1) {
      CartService.updateCart(item._id, -1, function (response) {
        calculate();
      });
    }
  }

  function plus(item) {
    CartService.updateCart(item._id, 1, function (response) {
      calculate();
    });
  }

  function prepareEditQty(item) {
    item.edit = true;
    vm.tempQty = item.qty;
  }

  function editQty(item) {
    if (vm.tempQty == null) return;

    item.edit = false;

    var calQty = vm.tempQty - item.qty;

    CartService.updateCart(
      item.productOid,
      item._id,
      calQty,
      function (response) {
        calculate();
      }
    );
  }

  function calculate() {
    blockUI.start();
    CartService.get(function (response) {
      vm.loadingPage = false;
      blockUI.stop();
      vm.products = vm.cart.products = response.data.products;
      vm.summary = vm.cart.summary = response.data.summary;
      vm.promotions = vm.cart.promotions = response.data.promotions;
      session.cart = vm.cart;
    });
  }

  function deleteItem(item) {
    CartService.updateCart(
      item.productOid,
      item._id,
      -item.qty,
      function (response) {
        calculate();
      }
    );
  }

  function shipment() {
    if (vm.cart.products.length == 0) {
      $translate("err.cart.noitem.header").then(function (header) {
        $translate("err.cart.noitem.detail").then(function (detail) {
          var config = {
            header: header,
            content1: detail,
          };
          ModalFactory.error(config, function (callback) {
            $state.go("app.home");
          });
        });
      });
    } else {
      var validCancel = true;
      var validQty = true;

      vm.cart.products.forEach(function (product) {
        if (product.status == 0) {
          validCancel = false;
        }

        if (!product.qty || product.qty == null) {
          validQty = false;
        }
      });

      if (validCancel && validQty) {
        $state.go("app.shipment");
      } else {
        if (validQty === false) {
          var config = {
            header: "ไม่สามารถดำเนินการต่อได้",
            content1:
              "กรุณาตรวจสอบจำนวนสินค้าในกระกร้าให้ถูกต้อง และ ลองใหม่อีกครั้ง.",
          };
          ModalFactory.error(config, function (callback) {});
        }

        if (validCancel === false) {
          $translate("err.cart.productCancel.header").then(function (header) {
            $translate("err.cart.productCancel.detail").then(function (detail) {
              var config = {
                header: header,
                content1: detail,
              };
              ModalFactory.error(config, function (callback) {});
            });
          });
        }
      }
    }
  }

  function saveTemplate() {
    if (vm.cart.products.length == 0) {
      $translate("err.cart.noitem.header").then(function (header) {
        $translate("err.cart.noitem.detail").then(function (detail) {
          var config = {
            header: header,
            content1: detail,
          };
          ModalFactory.error(config, function (callback) {
            $state.go("app.home");
          });
        });
      });
    } else {
      ModalFactory.saveTemplate({}, function (callback) {});
    }
  }
}
