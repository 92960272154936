app.controller("ContactController", ContactController);

ContactController.$inject = ["$translate", "$sessionStorage"];

function ContactController($translate, $sessionStorage) {
  var vm = this;
  var session = $sessionStorage;
  vm.navs = [];

  vm.init = init();

  function init() {
    $translate("menu.contact").then(function (data) {
      vm.navs[0] = { description: data };
    });

    const uluru = { lat: 13.745429, lng: 100.518701 };
    // The map, centered at Uluru
    const map = new this.google.maps.Map(document.getElementById("map"), {
      zoom: 17,
      center: uluru,
    });
    // The marker, positioned at Uluru
    const marker = new this.google.maps.Marker({
      position: uluru,
      map: map,
    });
  }
}
