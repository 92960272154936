angular.module("app").controller("ResetPasswordController", ResetPasswordController);

ResetPasswordController.$inject = ['$translate', 'UserService', '$stateParams', 'ModalFactory', '$state', 'blockUI'];

function ResetPasswordController($translate, UserService, $stateParams, ModalFactory, $state,blockUI) {
    var vm = this;

    //UI Variable
    vm.navs = [];
    vm.password;
    vm.confirmPassword;
    vm.isMacth = true;
    vm.key;

    //UI Function
    vm.init = init();
    vm.submitForm = submitForm;
    vm.setDrity = setDrity;

    function init() {
    	blockUI.start('Please wait...');
    	vm.key = $stateParams.key;
    	
    	if( vm.key == undefined || vm.key == null || vm.key == "" ){
    		blockUI.stop();
    		$translate(['err.user.resetPassword.header','err.user.resetPassword.content']).then(function (err) {
    			var config = {
        				header: err['err.user.resetPassword.header'],
        				content: err['err.user.resetPassword.content']
        		}
    			
    			ModalFactory.warning(config, function(result){
        			
        		})
        		$state.go('app.home');
    			return;
    		});
    	}else{
    		UserService.verifyKey(vm.key,function(result){
    			blockUI.stop();
    			if( result.responseCode != 0 ){
    				$translate(['err.user.resetPassword.header','err.user.resetPassword.content']).then(function (err) {
    	    			var config = {
    	        				header: err['err.user.resetPassword.header'],
    	        				content: err['err.user.resetPassword.content']
    	        		}
    	    			
    	    			ModalFactory.warning(config, function(result){
    	        			
    	        		})
    	        		$state.go('app.home');
    	    			return;
    	    		});
    			}
    		})
    	}
    	
        $translate('menu.resetPassword').then(function (data1) {
            vm.navs[0] = { description: data1 }
        });
    }
    
    function submitForm(){
    	if (vm.password != vm.confirmPassword) {
            vm.isMacth = false;
            return;
        }
    	
    	blockUI.start('Please wait...');
    	
    	
    	
    	UserService.resetPassword(vm.key, vm.password, function(result){
    		blockUI.stop();
    		if (result.responseCode == 0) {
            	
    			$translate(['success.user.resetPassword.header','success.user.resetPassword.content']).then(function (err) {
        			var config = {
            				header: err['success.user.resetPassword.header'],
            				content: err['success.user.resetPassword.content']
            		}
        			
        			ModalFactory.warning(config, function(result){
            			
            		})
            		$state.go('app.home');
        			return;
        		});
            } else {
                $translate(result.responseMsg).then(function (data) {
                    vm.responseMsg = data;
                });
            }
    		
    	});
    }
    
    function setDrity() {
        var form = vm.userForm;
        if (form.$invalid) {
            angular.forEach(form.$error, function (controls, errorName) {
                angular.forEach(controls, function (control) {
                    control.$setDirty();
                });
            });
        }
    }
}