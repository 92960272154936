app.service('ProvinceService', ProvinceService);

ProvinceService.$inject = ['$resource', 'CommonCallback', 'config'];

function ProvinceService($resource, CommonCallback, config) {
    var resource = $resource('', {}, {
        'getProvince': {
            method: 'GET',
            isArray: false,
            url: config.apiUrl + '/province'
        }
    });

    function getProvince(successCallBack) {
        resource.getProvince({},
            CommonCallback.onSuccess(successCallBack),
            CommonCallback.onError);
    }
    return {
        getProvince: getProvince
    };
}