app.service('Constant', Constant);

Constant.$inject = ['$translate'];

function Constant($translate) {
   
	function invoiceStatus(callback) {
    	
    	$translate(['invoice.status.1','invoice.status.2','invoice.status.3','invoice.status.4','invoice.status.5']).then(function (status) {
    		
    		callback([
    			status['invoice.status.1'],
    			status['invoice.status.2'],
    			status['invoice.status.3'],
    			status['invoice.status.4'],
    			status['invoice.status.5']
    			]);
		});
    	
    	
    }
	
	function banks(callback) {
		
		var banks = [];
		var bankObj = {};
    	
		$translate(['bank.bank1.name','bank.bank1.img','bank.bank1.accountName','bank.bank1.accountNo']).then(function (bank) {
    		
			bankObj = {};
			
			bankObj.name = bank['bank.bank1.name'];
			bankObj.img = bank['bank.bank1.img'];
			bankObj.accountName = bank['bank.bank1.accountName'];
			bankObj.accountNo = bank['bank.bank1.accountNo'];
			
			banks.push(bankObj);
			
			$translate(['bank.bank2.name','bank.bank2.img','bank.bank2.accountName','bank.bank2.accountNo']).then(function (bank) {
	    		
				bankObj = {};
				
				bankObj.name = bank['bank.bank2.name'];
				bankObj.img = bank['bank.bank2.img'];
				bankObj.accountName = bank['bank.bank2.accountName'];
				bankObj.accountNo = bank['bank.bank2.accountNo'];
				
				banks.push(bankObj);
				
				$translate(['bank.bank3.name','bank.bank3.img','bank.bank3.accountName','bank.bank3.accountNo']).then(function (bank) {
		    		
					bankObj = {};
					
					bankObj.name = bank['bank.bank3.name'];
					bankObj.img = bank['bank.bank3.img'];
					bankObj.accountName = bank['bank.bank3.accountName'];
					bankObj.accountNo = bank['bank.bank3.accountNo'];
					
					banks.push(bankObj);
					
					callback(banks);
					
				});
				
				
			});
			
		});
    	
    	
    }
    
    return{
    	invoiceStatus: invoiceStatus,
    	banks: banks
    }
}