app.factory('BrandService', BrandService);

BrandService.$inject = ['$resource', 'CommonCallback', 'config', '$sessionStorage'];

function BrandService($resource, CommonCallback, config, $sessionStorage) {

  var resource = $resource('', {}, {
    'gets': {
      method: 'POST',
      url: config.apiUrl + '/brand/gets'
    },
    'getBrandLetters': {
      method: 'POST',
      url: config.apiUrl + '/brand/getBrandLetters'
    }

  });

  function getBrandLetters(criteria, successCallBack) {


    resource.getBrandLetters({
      categoryId: criteria.categoryId,
      categoryLevel: criteria.categoryLevel,
    },
      CommonCallback.onSuccess(successCallBack),
      CommonCallback.onError);
  }


  function gets(brands, successCallBack) {


    resource.gets({
      brands: brands
    },
      CommonCallback.onSuccess(successCallBack),
      CommonCallback.onError);
  }


  return {
    gets: gets,
    getBrandLetters: getBrandLetters
  };
}
