angular
  .module("app")
  .controller("BrandFilterPopupController", BrandFilterPopupController);

BrandFilterPopupController.$inject = ["config", "$uibModalInstance"];

function BrandFilterPopupController(config, $uibModalInstance) {
  var vm = this;

  vm.brandLists = [];

  vm.init = init();
  vm.close = close;
  vm.submit = submit;
  vm.check = check;
  vm.clearAll = clearAll;

  function init() {
    var brandLists = config.brandLists;

    angular.forEach(brandLists, function (value, key) {
      var brandItem = {
        id: value.brandOid,
        name: value.brandNameThai,
        selected: false,
      };

      angular.forEach(config.selectedBrands, function (value2, key2) {
        if (value2.id == value.brandOid) {
          brandItem.selected = true;
        }
      });

      vm.brandLists.push(brandItem);
    });
  }

  function close() {
    $uibModalInstance.dismiss("cancel");
  }

  function submit() {
    var selectedBrands = [];
    angular.forEach(vm.brandLists, function (value, key) {
      if (value.selected) {
        var brandItem = { id: value.id, name: value.name };
        selectedBrands.push(brandItem);
      }
    });

    $uibModalInstance.close(selectedBrands);
  }

  function check(brandItem) {
    brandItem.selected = !brandItem.selected;
  }

  function clearAll() {
    angular.forEach(vm.brandLists, function (value, key) {
      value.selected = false;
    });
  }
}
