angular.module("app").controller("FristTimeController", FristTimeController);

FristTimeController.$inject = [
  "$translate",
  "UserService",
  "$stateParams",
  "ModalFactory",
  "$state",
  "$sessionStorage",
  "CartService",
  "blockUI",
  "$cookies",
];

function FristTimeController(
  $translate,
  UserService,
  $stateParams,
  ModalFactory,
  $state,
  $sessionStorage,
  CartService,
  blockUI,
  $cookies
) {
  var vm = this;
  var session = $sessionStorage;

  //UI Variable
  vm.navs = [];
  vm.password;
  vm.confirmPassword;
  vm.isMacth = true;
  vm.key;

  //UI Function
  vm.init = init();
  vm.submitForm = submitForm;
  vm.setDrity = setDrity;

  function init() {
    vm.key = $stateParams.key;

    if (vm.key == undefined || vm.key == null || vm.key == "") {
      return $state.go("app.home");
    } else {
      UserService.checkUserHavePassword(vm.key, function (result) {
        if (result.responseCode != 0 || result.data.isHavePassword) {
          return $state.go("app.home");
        }
      });
    }

    $translate("menu.fristTime").then(function (data1) {
      vm.navs[0] = { description: data1 };
    });
  }

  function submitForm() {
    if (vm.password != vm.confirmPassword) {
      vm.isMacth = false;
      return;
    }

    var user = {
      key: vm.key,
      email: vm.key,
      newPassword: vm.password,
      password: vm.password,
    };

    // console.log(vm.changePass);
    UserService.changePassword(user, function (response) {
      blockUI.stop();
      if (response.responseCode == 0) {
        $translate(["success.user.changePassword.success"]).then(function (
          err
        ) {
          var config = {
            header: err["success.user.changePassword.success"],
          };

          UserService.login(user, function (response) {
            if (response.responseCode == 0) {
              session.user = response.data;
              CartService.get(function (response) {
                var cart = {};
                cart.products = response.data.products;
                cart.summary = response.data.summary;
                session.cart = cart;

                $cookies.put("YSC_USER", JSON.stringify(session.user));
                $cookies.put("YSC_CART", JSON.stringify(session.cart));
                return $state.go("app.home");
              });
            }
          });

          ModalFactory.warning(config, function (result) {});
        });
      } else {
        $translate(response.responseMsg).then(function (data) {
          vm.responseMsg = data;
        });
      }
    });
  }

  function setDrity() {
    var form = vm.userForm;
    if (form.$invalid) {
      angular.forEach(form.$error, function (controls, errorName) {
        angular.forEach(controls, function (control) {
          control.$setDirty();
        });
      });
    }
  }
}
