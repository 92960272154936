angular.module("app").factory('AddToCartModalFactory', AddToCartModalFactory);

AddToCartModalFactory.$inject = ['$uibModal'];

function AddToCartModalFactory($uibModal) {

    function open(config, thenCallback, cancelCallBack) {
        $uibModal.open({
            templateUrl: 'app/modal/addToCart.success.modal.html',
            controller: 'AddToCartModalController',
            controllerAs: 'vm',
            backdrop: 'static',
            keyboard: false,
            size: "xs",
            resolve: {
                config: function () {
                    return config;
                }
            }
        }).result.then(function () {
            if (thenCallback) {
                thenCallback();
            }
        }, function () {
            if (cancelCallBack) {
                cancelCallBack();
            }
        });
    }

    return {
        open: open
    }

}