var app = angular.module("app", [
  "ui.router",
  "ngCookies",
  "pascalprecht.translate",
  "ngAnimate",
  "ui.bootstrap",
  "ngResource",
  "ngTouch",
  "ngStorage",
  "AnimatedScroll",
  "angularValidator",
  "blockUI",
  "vcRecaptcha",
]);

app.run([
  "$rootScope",
  "$anchorScroll",
  "TestService",
  "$cookies",
  "$sessionStorage",
  function ($rootScope, $anchorScroll, TestService, $cookies, $sessionStorage) {
    $rootScope.$on("$locationChangeSuccess", function () {
      $("#mobileMenu").removeClass("in");
      $("#hamberger").removeClass("fa-close").addClass("fa-navicon");
      $anchorScroll();
    });

    TestService.connect(function (result) {});

    if ($cookies.get("YSC_USER") && $cookies.get("YSC_CART")) {
      $sessionStorage.user = JSON.parse($cookies.get("YSC_USER"));
      $sessionStorage.cart = JSON.parse($cookies.get("YSC_CART"));
    }
  },
]);
