angular.module("app").controller("ShipmentController", ShipmentController);

ShipmentController.$inject = [
  "$translate",
  "$state",
  "$sessionStorage",
  "POService",
  "$uibModal",
  "ModalFactory",
  "blockUI",
  "UserService",
];

function ShipmentController(
  $translate,
  $state,
  $sessionStorage,
  POService,
  $modal,
  ModalFactory,
  blockUI,
  UserService
) {
  var vm = this;
  var session = $sessionStorage;
  var mvm = MainController;

  /* Nav Bar Setup */
  vm.navs = [];

  /* valiable */
  vm.cart = {};
  vm.summary = {};

  vm.deliveryAddressObj;
  vm.taxAddressObj;

  vm.deliveryName;
  vm.deliveryAddress;
  vm.deliveryAddress2;
  vm.deliveryProvince;
  vm.deliveryProvinceName;
  vm.deliveryPostCode;
  vm.deliveryTel;
  vm.selectedShiping = true;
  vm.selectedPickup = false;
  vm.editAddressFlag = false;
  vm.partialInvoice = "true";
  vm.cod = undefined;
  vm.remark;

  //UI function
  vm.init = init();
  vm.create = create;
  vm.select = select;
  vm.selectAddress = selectAddress;
  vm.createAddress = createAddress;
  vm.checkCreateAddress = checkCreateAddress;
  vm.alertIsDeafult = alertIsDeafult;

  function init() {
    if (
      !session.cart ||
      !session.user ||
      !session.cart.products ||
      session.cart.products.length == 0
    ) {
      $state.go("app.home");
      return;
    }

    blockUI.start();

    vm.cart = session.cart;
    vm.summary = session.cart.summary;

    vm.editAddressFlag = true;
    vm.partialInvoice = "true";

    UserService.getAddresses(session.user._id, function (result) {
      session.user.addresses = result.data;

      vm.user = session.user;

      for (let index = 0; index < session.user.addresses.length; index++) {
        const address = session.user.addresses[index];
        if (address.isDefault && address.type == "Shipping") {
          vm.deliveryAddressObj = Object.assign({}, address);
        }

        if (address.isDefault && address.type == "Tax") {
          vm.taxAddressObj = Object.assign({}, address);
        }
      }

      blockUI.stop();
    });

    // resetAddress();

    $translate("menu.cart").then(function (data1) {
      $translate("header.shipment").then(function (data2) {
        vm.navs[0] = { description: data1, href: "/ecommerce/cart" };
        vm.navs[1] = { description: data2 };
      });
    });
  }

  function select(param) {
    if (param == 1) {
      vm.selectedShiping = true;
      vm.selectedPickup = false;
    } else {
      vm.selectedShiping = false;
      vm.selectedPickup = true;
    }
  }

  function create() {
    let isNONVAT = false;
    if (session.user.vatType == "NONVAT" || session.user.vatType == "A") {
      isNONVAT = true;
    }

    if (
      (vm.selectedShiping && !vm.cod) ||
      (vm.selectedShiping && !vm.deliveryAddressObj) ||
      (!isNONVAT && !vm.taxAddressObj) ||
      (isNONVAT && !vm.deliveryAddressObj)
    ) {
      $translate("err.shipment.required.header").then(function (header) {
        $translate("err.shipment.required.detail").then(function (detail) {
          var config = {
            header: header,
            content1: detail,
          };
          ModalFactory.error(config, function (callback) {});
        });
      });
    } else {
      blockUI.start();

      let delivery = {};
      let billingAddress = {};

      if (vm.deliveryAddressObj) {
        delivery = {
          name: vm.deliveryAddressObj.name,
          line1: vm.deliveryAddressObj.line1,
          line2: vm.deliveryAddressObj.line2,
          province: vm.deliveryAddressObj.province,
          postcode: vm.deliveryAddressObj.postcode,
          tel: vm.deliveryAddressObj.tel,
        };
      }

      if (vm.taxAddressObj) {
        billingAddress = {
          name: vm.taxAddressObj.name,
          line1: vm.taxAddressObj.line1,
          line2: vm.taxAddressObj.line2,
          province: vm.taxAddressObj.province,
          postcode: vm.taxAddressObj.postcode,
          tel: vm.taxAddressObj.tel,
          taxId: vm.taxAddressObj.taxId,
        };
      }

      var data = {
        name: vm.deliveryName,
        delivery: delivery,
        billing: billingAddress,
        cod: "true" == vm.cod ? true : false,
        partialInvoice: "true" == vm.partialInvoice ? true : false,
        remark: vm.remark,
        deliveryType: vm.selectedShiping ? "S" : "P",
      };

      POService.create(data, function (result) {
        blockUI.stop();

        if (result.responseCode == 0) {
          session.cart = { products: [] };
          openPurchaseSuccessModal(result.data);
        }
      });
    }
  }

  function openPurchaseSuccessModal(orderNumber) {
    var config;
    $translate("shipment.purchaseSuccess").then(function (data1) {
      $translate("shipment.content1").then(function (data2) {
        $translate("shipment.content2").then(function (data3) {
          $modal
            .open({
              templateUrl: "app/modal/shipment.success.modal.html",
              controller: "SuccessModalController",
              controllerAs: "vm",
              keyboard: false,
              size: "xs   ",
              backdrop: "static",
              resolve: {
                config: function () {
                  return {
                    header: data1,
                    content1: data2,
                    content2: data3,
                    content3: orderNumber,
                  };
                },
              },
            })
            .result.then(
              function () {
                $state.go("app.home");
              },
              function () {}
            );
        });
      });
    });
  }

  function createAddress(type) {
    let haveIsDefault = false;
    for (let index = 0; index < session.user.addresses.length; index++) {
      const tempAddress = session.user.addresses[index];
      if (
        tempAddress.type == type &&
        tempAddress.status != "inactive" &&
        tempAddress.isDefault === true
      ) {
        haveIsDefault = true;
      }
    }

    let isDefault = false;
    if (haveIsDefault === false) {
      isDefault = true;
    }

    ModalFactory.formAddress(
      {
        header: "เพิ่มที่อยู่ใหม่",
        data: {
          isDefault: isDefault,
          type: type,
          lockType: true,
          lockDefault: isDefault,
        },
      },
      function (address) {
        let data = {
          _id: session.user._id,
          address: address,
        };

        blockUI.start();
        UserService.createAddress(data, function (result) {
          blockUI.stop();
          vm.user = session.user = result.data;
          if (type == "Shipping") {
            vm.deliveryAddressObj = Object.assign({}, address);
          } else if (type == "Tax") {
            vm.taxAddressObj = Object.assign({}, address);
          }
          var config = {
            header: "บันทึกข้อมูลที่อยู่สำเร็จ",
            content: "บันทึกข้อมูลที่อยู่สำเร็จ",
          };
          ModalFactory.success(config, function (callback) {});
        });
      }
    );
  }

  function checkCreateAddress(type) {
    blockUI.start();

    UserService.getAddresses(session.user._id, function (result) {
      session.user.addresses = result.data;

      let addresses = [];
      for (let index = 0; index < session.user.addresses.length; index++) {
        const address = session.user.addresses[index];
        if (address.type == type && address.status != "inactive") {
          addresses.push(address);
        }
      }

      blockUI.stop();

      if (addresses.length > 0) {
        selectAddress(type);
      } else {
        createAddress(type);
      }
    });
  }

  function selectAddress(type) {
    blockUI.start();

    UserService.getAddresses(session.user._id, function (result) {
      session.user.addresses = result.data;

      let addresses = [];
      for (let index = 0; index < session.user.addresses.length; index++) {
        const address = session.user.addresses[index];
        if (address.type == type && address.status != "inactive") {
          addresses.push(address);
        }
      }

      blockUI.stop();

      ModalFactory.selectAddress(
        { header: "เลือกที่อยู่", addresses: addresses },
        function (address) {
          if (!address) {
            vm.createAddress(type);
          } else {
            if (type == "Shipping") {
              vm.deliveryAddressObj = Object.assign({}, address);
            } else if (type == "Tax") {
              vm.taxAddressObj = Object.assign({}, address);
            }

            vm.alertIsDeafult(address, type);
          }
        }
      );
    });
  }

  function alertIsDeafult(address, type) {
    let haveIsDefault = false;
    for (let index = 0; index < session.user.addresses.length; index++) {
      const address = session.user.addresses[index];
      if (
        address.type == type &&
        address.status != "inactive" &&
        address.isDefault === true
      ) {
        haveIsDefault = true;
      }
    }

    if (haveIsDefault === false) {
      ModalFactory.confirm(
        {
          header: "ตั้งค่าที่อยู่เป็นค่าตั้งต้น",
          body: "ยืนยันการตั้งค่าที่อยู่นี้เป็นค่าตั้งต้นหรือไม่?",
          remark:
            "* การสั่งซื้อครั้งต่อไปจะนำค่าตั้งต้นมาใช้งานเสมอ (สามารถเปลี่ยนได้)",
          yes: "ต้องการ",
          no: "ไม่ต้องการ",
        },
        function (result) {
          console.log(result);
          if (result) {
            address.isDefault = true;

            let data = {
              _id: session.user._id,
              address: address,
            };

            blockUI.start();
            UserService.updateAddress(data, function (result) {
              blockUI.stop();
              session.user = result.data;
            });
          }
        }
      );
    }
  }
}
