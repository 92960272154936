app.factory('InvoiceService', InvoiceService);

InvoiceService.$inject = [ '$resource', 'CommonCallback', 'config', '$sessionStorage' ];

function InvoiceService($resource, CommonCallback, config, $sessionStorage) {
	
	var userId = undefined;
	if( $sessionStorage.user ){
		userId = $sessionStorage.user._id;
	}
	
	var resource = $resource('', {}, {
	       'get': { 
	        	method: 'GET',
	        	url: config.apiUrl+'/invoice/:invoiceNo'
	        },
	        'payment': { 
	        	method: 'PUT',
	        	headers: {'Content-Type': undefined},
	        	url: config.apiUrl+'/invoice'
	        },
			'search': {
				method: 'POST',
				url: config.apiUrl + '/invoice/search'
			},
			'gets': {
				method: 'POST',
				url: config.apiUrl + '/invoice/gets'
			},
			'savePayment': {
				method: 'POST',
				url: config.apiUrl + '/payment/save'
			}
	        
	    });
	
		function get(invoiceNo, successCallBack) {
			resource.get({
				invoiceNo: invoiceNo
			}, 
			CommonCallback.onSuccess(successCallBack), 
			CommonCallback.onError);
		}
		
		function payment( data ,successCallBack) {
			
			var fd = new FormData();
			
			fd.append('invoices',JSON.stringify(data.invoices));
			fd.append('paymentType',data.paymentType);
			
			if( data.paymentType == 2 ){
				fd.append('file', data.uploadSlip.img);
				
				data.uploadSlip.img = undefined;
				
				fd.append('uploadSlip',JSON.stringify(data.uploadSlip));
			}
			
			fd.append('system','ysc-ecommerce');
			fd.append('userId',userId);
			fd.append('totalMemberPrice',data.totalMemberPrice);
			
			fd.append('paymentKey',data.paymentKey);
			
			resource.payment(fd, 
			CommonCallback.onSuccess(successCallBack), 
			CommonCallback.onError);
		}
		
		function search(data, successCallBack) {
			resource.search(data,
				CommonCallback.onSuccess(successCallBack),
				CommonCallback.onError);
		}
		
		function gets( data ,successCallBack) {
			resource.gets({
				invoices: data.invoices,
				userId: userId
			}, 
			CommonCallback.onSuccess(successCallBack), 
			CommonCallback.onError);
		}
		
		function savePayment( data ,successCallBack) {
			resource.savePayment(data, 
			CommonCallback.onSuccess(successCallBack), 
			CommonCallback.onError);
		}
		
	    return {
	    	payment: payment,
	    	get: get,
			search: search,
			gets: gets,
			savePayment: savePayment
	    };
}
