app.factory("ProductService", ProductService);

ProductService.$inject = [
  "$resource",
  "CommonCallback",
  "config",
  "$sessionStorage",
];

function ProductService($resource, CommonCallback, config, $sessionStorage) {
  var resource = $resource(
    "",
    {},
    {
      getsProductByGroup: {
        method: "GET",
        isArray: false,
        url: config.apiUrl + "/getProductsByGroup/:id",
      },
      getProducts: {
        method: "GET",
        isArray: false,
        url: config.apiUrl + "/products",
      },
      get: {
        method: "GET",
        isArray: false,
        url: config.apiUrl + "/product/:id",
      },
      getWishlistProduct: {
        method: "GET",
        url: config.apiUrl + "/wishlistProducts",
      },
    }
  );

  function get(id, successCallBack) {
    var email = undefined;
    if ($sessionStorage.user) {
      email = $sessionStorage.user._id;
    }

    resource.get(
      {
        id: id,
        email: email,
      },
      CommonCallback.onSuccess(successCallBack),
      CommonCallback.onError
    );
  }

  function getProductsByGroup(criteria, successCallBack) {
    var email = undefined;
    if ($sessionStorage.user) {
      email = $sessionStorage.user._id;
    }

    resource.getsProductByGroup(
      {
        id: criteria.groupId,
        page: criteria.page,
        itemPerPage: criteria.itemPerPage,
        order: criteria.order,
        email: email,
      },
      CommonCallback.onSuccess(successCallBack),
      CommonCallback.onError
    );
  }

  function getProducts(criteria, successCallBack) {
    let email = undefined;
    let vatType = "VAT";
    if ($sessionStorage.user) {
      email = $sessionStorage.user._id;
      vatType = $sessionStorage.user.vatType;
    }

    resource.getProducts(
      {
        page: criteria.page,
        itemPerPage: criteria.itemPerPage,
        searchText: criteria.searchText,
        order: criteria.order,
        categoryId: criteria.categoryId,
        categoryLevel: criteria.categoryLevel,
        brandIds: criteria.brandIds,
        exceptItem: criteria.exceptItem,
        minAmt: criteria.minAmt,
        maxAmt: criteria.maxAmt,
        email: email,
        p: criteria.p,
        vatType: vatType,
        productGroupOid: criteria.productGroupOid,
      },
      CommonCallback.onSuccess(successCallBack),
      CommonCallback.onError
    );
  }

  function getWishlistProduct(criteria, successCallBack) {
    var email = undefined;
    if ($sessionStorage.user) {
      email = $sessionStorage.user._id;
    }

    resource.getWishlistProduct(
      {
        page: criteria.page,
        itemPerPage: criteria.itemPerPage,
        email: email,
      },
      CommonCallback.onSuccess(successCallBack),
      CommonCallback.onError
    );
  }

  return {
    getProductsByGroup: getProductsByGroup,
    getProducts: getProducts,
    get: get,
    getWishlistProduct: getWishlistProduct,
  };
}
