app.factory("CommonCallback", CommonCallback);

CommonCallback.$inject = [
  "AlertModalService",
  "$log",
  "$translate",
  "ModalFactory",
  "blockUI",
];

function CommonCallback(
  AlertModalService,
  $log,
  $translate,
  ModalFactory,
  blockUI
) {
  function onSuccess(successCallBack) {
    return function (response, headers) {
      successCallBack.apply(response, arguments);
    };
  }

  function onError(httpResponse) {
    blockUI.stop();
    var callback = function () {};
    $log.log(httpResponse);
    AlertModalService.danger(
      httpResponse.data.error
        ? httpResponse.data.error
        : httpResponse.statusText,
      callback
    );
  }

  function onConnectionError(httpResponse) {
    var callback = function () {};

    $translate([
      "err.connection.header",
      "err.connection.content1",
      "err.connection.content2",
    ]).then(function (error) {
      var configx = {};
      configx.header = error["err.connection.header"];
      configx.content1 = error["err.connection.content1"];
      configx.content2 = error["err.connection.content2"];
      configx.isLock = true;

      ModalFactory.error(configx, function (result) {});
    });
  }

  return {
    onSuccess: onSuccess,
    onError: onError,
    onConnectionError: onConnectionError,
  };
}
