angular.module("app").controller('PurchaseOrderTemplateController', PurchaseOrderTemplateController);

PurchaseOrderTemplateController.$inject = ['$translate', '$state', '$sessionStorage', 'PoTemplateService', 'PagingService', 'ModalFactory', 'UserService', 'blockUI'];

function PurchaseOrderTemplateController($translate, $state, $sessionStorage, PoTemplateService, PagingService, ModalFactory, UserService, blockUI) {

    var vm = this;
    var session = $sessionStorage;

    /*UI variable*/
    vm.criteria = {};
    vm.paging = {};
    vm.navs = [];
    vm.userMenu = 'purchaseOrderTemplate';

    /*UI function*/
    vm.int = init();
    vm.selectedPage = selectedPage;
    vm.selector = selector;
    vm.substring = substring;
    vm.deleteTemplate = deleteTemplate;

    function init() {

    	vm.loadingPage = true;
    	
    	if (!session.user) {
			$state.go('app.home');
		}

        vm.criteria = {
            itemPerPage: 10,
            page: 1,
            userId: session.user._id
        };
        $translate('menu.poTemplate').then(function (data) {
            vm.navs[0] = { description: data};
        });
        search();
    }

    function selectedPage(page) {
        vm.criteria.page = page;
        search();
    }

    function selector(id) {
        $state.go('app.purchaseOrderTemplateSelector', { poTemplateId: id });
    }


    function substring(string) {
        if (string.length > 30) {
            return string.substring(0, 30) + ' ...';
        } else {
            return string;
        }
    }

    function search(){
        PoTemplateService.search(vm.criteria, function(response){
        	vm.loadingPage = false;
            if (response.responseCode == 0) {
                vm.poTemplates = response.data;
                PagingService.get(vm.criteria.page, vm.criteria.itemPerPage, response.totalItems, function (response) {
                    vm.paging = response;
                });
            }
        })
    }

    function deleteTemplate(id) {
        ModalFactory.confirmDelete({}, function () {
        	blockUI.start();
            PoTemplateService.deleteTemplate(id, function (response) {
            	blockUI.stop();
                if (response.responseCode == 0) {
                    $translate('deleteSuccess').then(function (data) {
                        var config = {
                            header: data
                        }
                        ModalFactory.success(config, function () {
                            // $state.go('app.purchaseOrderTemplate');
                            search();
                        })
                    });
                }
            })
        })
    }
}

