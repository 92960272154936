angular.module("app").component("templateHeader", {
  templateUrl: "app/template/header.html",
  controller: HeaderController,
  controllerAs: "vm",
});

HeaderController.$inject = [
  "animatedScroll",
  "ProductGroupService",
  "$sessionStorage",
  "$uibModal",
  "$state",
  "ProductCategoryService",
  "ModalFactory",
  "$cookies",
  "blockUI",
];

function HeaderController(
  animatedScroll,
  ProductGroupService,
  $sessionStorage,
  $modal,
  $state,
  ProductCategoryService,
  ModalFactory,
  $cookies,
  blockUI
) {
  var vm = this;
  var session = $sessionStorage;

  vm.textSearch = "";
  vm.productGroups = [];
  vm.categories;
  vm.user = {};

  vm.init = init();
  vm.scrollingTo = scrollingTo;
  vm.search = search;
  vm.openLoginModal = openLoginModal;
  vm.getUserLogin = getUserLogin;
  vm.accountMenuClick = accountMenuClick;
  vm.getCart = getCart;
  vm.viewGroup = viewGroup;
  vm.clickMobileMenu = clickMobileMenu;

  function init() {
    vm.openMenu = false;

    settingHeader();
    mapSession();

    if (!session.categories)
      ProductCategoryService.getCategories(function (response) {
        session.categories = vm.categories = response.data;
      });

    if (!session.productGroups)
      ProductGroupService.getProductGroups(function (response) {
        //Inquiry Product Groups
        session.productGroups = vm.productGroups = response.data;
      });
  }

  function scrollingTo(param) {
    //		$state.go('app.home').then(function () {
    //			animatedScroll.scroll(param);
    //		});

    var config = {
      categories: vm.categories,
    };

    ModalFactory.menuPopup(config, function (result) {});
  }

  function search() {
    if (vm.textSearch != undefined && vm.textSearch !== "")
      $state.go("app.searchProduct", { text: vm.textSearch });
    // vm.textSearch = '';
  }

  function openLoginModal() {
    $modal
      .open({
        templateUrl: "app/user/login.html",
        controller: "LoginController",
        controllerAs: "vm",
        keyboard: false,
        size: "lg",
        resolve: {
          config: function () {
            return {
              mode: "",
              text: "",
            };
          },
        },
      })
      .result.then(
        function () {
          // $state.go('app.home');
          location.reload();
        },
        function () {}
      );
  }

  function mapSession() {
    vm.productGroups = session.productGroups;
    vm.categories = session.categories;
  }

  function getUserLogin() {
    vm.user = session.user;
    return session.user;
  }

  function getCart() {
    return session.cart;
  }

  function viewGroup(groupId) {
    $state.go("app.viewGroup", { id: groupId });
  }

  function clickMobileMenu() {
    if ($("#hamberger").hasClass("fa-navicon")) {
      $("#hamberger").removeClass("fa-navicon").addClass("fa-close");
    } else {
      $("#hamberger").removeClass("fa-close").addClass("fa-navicon");
    }
  }

  function accountMenuClick(menu) {
    session.criteria = undefined;

    switch (menu) {
      case "information":
        $state.go("app.information");
        break;
      case "purchaseOrder":
        $state.go("app.purchaseOrder");
        break;
      case "template":
        $state.go("app.purchaseOrderTemplate");
        break;
      case "invoice":
        $state.go("app.invoice");
        break;
      case "wishlist":
        $state.go("app.wishlist");
        break;
      case "change-password":
        $state.go("app.changePassword");
        break;
      case "logout":
        vm.user = session.user = session.cart = undefined;
        $cookies.remove("YSC_USER");
        $cookies.remove("YSC_CART");
        $state.go("app.home");
        break;
    }
  }

  function settingHeader() {
    var width = window.innerWidth > 0 ? window.innerWidth : screen.width;

    if (width > 768) {
      $(window).scroll(function () {
        if ($(this).scrollTop() > 1) {
          // if (!$('#body').hasClass('fixed')) {
          //   $('#body').stop().addClass('fixed')
          //     .css('top', '-50px')
          //     .css('z-index', '100')
          //     .css('width', '100%')
          //     .css('background-color', '#0071bc')
          //     .animate({
          //       'top': '0px'
          //     }, 500);
          // }
          // $('#logo').css('display', 'none');
          // $('#allCategory').css('display', '');
          // $('#vcenter').css('height', '50px');
          // $('#content').css('height', '50px');
          // $('#content .top').css('height', '50px');
          // $('#content .icon').css('color', '#fff');
          // $('#vcenter input').addClass('placeholderWhite');
          // $('#iconUser').css('display', '');
          // $('.header-Username .dropdown').removeClass('open');
        } else {
          $("#body").removeClass("fixed").css("background-color", "");
          $("#logo").css("display", "");
          $("#allCategory").css("display", "none");
          $("#content").removeAttr("style");
          $("#content .top").removeAttr("style");
          $("#vcenter").removeAttr("style");
          $("#content .icon").css("color", "");
          $("#vcenter input").removeClass("placeholderWhite");
          $("#iconUser").css("display", "none").removeClass("open");
        }
      });
    }
  }
}
