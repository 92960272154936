(function () {
  angular
    .module("app")
    .controller("InformationController", InformationController);

  InformationController.$inject = [
    "$sessionStorage",
    "$translate",
    "UserService",
    "ModalFactory",
    "blockUI",
  ];

  function InformationController(
    $sessionStorage,
    $translate,
    UserService,
    ModalFactory,
    blockUI
  ) {
    var vm = this;
    var mvm = MainController;

    var session = $sessionStorage;

    //UI variable
    vm.user = {};
    vm.userMenu = "information";
    /* Nav Bar Setup */
    vm.navs = [];

    vm.editAddressFlag = false;

    /*UI function*/
    vm.int = init();
    vm.createAddress = createAddress;
    vm.editAddress = editAddress;
    vm.removeAddress = removeAddress;

    function init() {
      vm.loadingPage = true;

      getUserLogin();

      if (!vm.user) {
        $state.go("app.home");
      }

      $translate("menu.userInformation").then(function (menu) {
        vm.navs[0] = { description: menu };
      });

      UserService.getAddresses(vm.user._id, function (result) {
        console.log(result);

        vm.user.addresses = session.user.addresses = result.data;
        vm.loadingPage = false;
      });
    }

    function getUserLogin() {
      vm.user = session.user;
    }

    function editAddress(ecommerceKey) {
      let data = {
        _id: vm.user._id,
        ecommerceKey: ecommerceKey,
      };

      blockUI.start();

      UserService.getAddress(data, function (addressData) {
        blockUI.stop();

        ModalFactory.formAddress(
          {
            header: "แก้ไขข้อมูลที่อยู่",
            data: Object.assign({}, addressData.data),
          },
          function (address) {
            let data = {
              _id: vm.user._id,
              address: address,
            };

            blockUI.start();
            UserService.updateAddress(data, function (result) {
              blockUI.stop();
              vm.user = session.user = result.data;
              var config = {
                header: "บันทึกข้อมูลที่อยู่สำเร็จ",
                content: "บันทึกข้อมูลที่อยู่สำเร็จ",
              };
              ModalFactory.success(config, function (callback) {});
            });
          }
        );
      });
    }

    function removeAddress(ecommerceKey) {
      ModalFactory.confirmDelete({}, function () {
        let data = {
          _id: vm.user._id,
          address: {
            ecommerceKey: ecommerceKey,
          },
        };

        blockUI.start();
        UserService.removeAddress(data, function (result) {
          blockUI.stop();
          vm.user = session.user = result.data;
          var config = {
            header: "บันทึกข้อมูลที่อยู่สำเร็จ",
            content: "บันทึกข้อมูลที่อยู่สำเร็จ",
          };
          ModalFactory.success(config, function (callback) {});
        });
      });
    }

    function createAddress() {
      ModalFactory.formAddress(
        {
          header: "เพิ่มที่อยู่ใหม่",
          data: {
            isDefault: false,
          },
        },
        function (address) {
          let data = {
            _id: vm.user._id,
            address: address,
          };

          blockUI.start();
          UserService.createAddress(data, function (result) {
            blockUI.stop();
            vm.user = session.user = result.data;
            var config = {
              header: "บันทึกข้อมูลที่อยู่สำเร็จ",
              content: "บันทึกข้อมูลที่อยู่สำเร็จ",
            };
            ModalFactory.success(config, function (callback) {});
          });
        }
      );
    }
  }
})();
