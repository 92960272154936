app.controller('HowtoController', HowtoController);

HowtoController.$inject = ['$translate', '$sessionStorage'];

function HowtoController($translate, $sessionStorage) {
	
	var vm = this;
	var session = $sessionStorage;
	vm.navs = [];
	
	vm.init = init();
	
	function init(){
		$translate('menu.howto').then(function (data) {
            vm.navs[0] = { description: data }
        });
	}
	
}