angular.module("app").controller("MainController", MainController);

MainController.$inject = [
  "$cookies",
  "$translate",
  "$sessionStorage",
  "UserService",
  "CartService",
  "ProvinceService",
  "AddToCartModalFactory",
  "$state",
  "blockUI",
  "ConfigService",
  "HomePopupService",
  "ModalFactory",
  "$uibModal",
];

function MainController(
  $cookies,
  $translate,
  $sessionStorage,
  UserService,
  CartService,
  ProvinceService,
  AddToCartModalFactory,
  $state,
  blockUI,
  ConfigService,
  HomePopupService,
  ModalFactory,
  $modal
) {
  var vm = this;
  var session = $sessionStorage;

  vm.provinces;
  vm.configData;

  vm.userLogon = userLogon;
  vm.init = init();
  vm.wishlist = wishlist;
  vm.addToCart = addToCart;
  vm.accountMenuClick = accountMenuClick;

  function init() {
    if ($cookies.get("lang") == undefined) {
      $cookies.put("lang", "th");
    }
    $translate.use($cookies.get("lang"));

    if (!session.homePopup) {
      HomePopupService.get(function (response) {
        session.homePopup = response.data;
        response.data.src =
          response.data.src + "?v=" + response.data.objLog.updatedDate;
        if (response.data.enable) {
          ModalFactory.homePopup(response.data, function (result) {});
        }
      });
    }

    ConfigService.get(function (response) {
      vm.configData = response.data;
    });

    if (!session.provinces) {
      ProvinceService.getProvince(function (response) {
        session.provinces = vm.provinces = response.data;
      });
    } else {
      vm.provinces = session.provinces;
    }
  }

  function wishlist(product) {
    blockUI.start();
    UserService.wishlist(session.user._id, product._id, function (result) {
      blockUI.stop();
      product.wishlist = !product.wishlist;
    });
  }

  function addToCart(item, qty) {
    blockUI.start();
    if (!session.user) {
      blockUI.stop();
      $modal
        .open({
          templateUrl: "app/user/login.html",
          controller: "LoginController",
          controllerAs: "vm",
          keyboard: false,
          size: "lg",
          resolve: {
            config: function () {
              return {
                mode: "",
                text: "",
              };
            },
          },
        })
        .result.then(
          function () {
            // $state.go('app.home');
            location.reload();
          },
          function () {}
        );
      return;
    }

    CartService.updateCart(item.productOid, item._id, qty, function (response) {
      blockUI.stop();
      session.cart = response.data;

      var config = {
        name: item.name,
        img: item.imageURLPath,
        qty: qty,
        unit: item.unit,
        normalPrice: item.normalPrice * qty,
        memberPrice: item.memberPrice * qty,
      };

      if (item.discountValue) {
        if (item.discountType != "P") {
          config.normalPrice = (item.normalPrice - item.discountValue) * qty;
          config.memberPrice = (item.memberPrice - item.discountValue) * qty;
        } else {
          config.normalPrice =
            (item.normalPrice - (item.normalPrice * item.discountValue) / 100) *
            qty;
          config.memberPrice =
            (item.memberPrice - (item.memberPrice * item.discountValue) / 100) *
            qty;
        }
      }

      config.productName = item.name;
      AddToCartModalFactory.open(config, function (result) {});
    });
  }

  function accountMenuClick(menu) {
    session.criteria = undefined;

    switch (menu) {
      case "information":
        $state.go("app.information");
        break;
      case "purchaseOrder":
        $state.go("app.purchaseOrder");
        break;
      case "template":
        $state.go("app.purchaseOrderTemplate");
        break;
      case "invoice":
        $state.go("app.invoice");
        break;
      case "wishlist":
        $state.go("app.wishlist");
        break;
      case "change-password":
        $state.go("app.changePassword");
        break;
      case "logout":
        vm.user = session.user = session.cart = undefined;
        $cookies.remove("YSC_USER");
        $cookies.remove("YSC_CART");
        $state.go("app.home");
        break;
    }
  }

  function userLogon() {
    return session.user;
  }
}
