angular.module("app").controller("SelectTemplateModalController", SelectTemplateModalController);

SelectTemplateModalController.$inject = ['$uibModalInstance'];

function SelectTemplateModalController($modalInstance) {
    var vm = this;

    vm.close = close;
    vm.continueShopping = continueShopping;

    function close() {
        $modalInstance.close();
    }

    function continueShopping(){
        $modalInstance.dismiss("");
    }
}