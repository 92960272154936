angular.module("app").controller('UploadController', UploadController);

UploadController.$inject = ['$translate', '$state', 'CartService', '$sessionStorage', '$uibModal', 'ModalFactory', 'TestService'];

function UploadController($translate, $state, CartService, $sessionStorage, $modal, ModalFactory, TestService) {
	var vm = this;
	var session = $sessionStorage;
	
	vm.file;
	
	vm.upload = upload;

	
	function upload(){
		
//		console.log(vm.file);
//		
//		var data = {}
//		
//		data.file = vm.file;
//		
//		var fd = new FormData();
//		fd.append('file1', vm.file);
//		fd.append('file2', vm.file);
//		
//		var customer = {name:'mod'};
//		
//		
//		
//        fd.append('customer',JSON.stringify(customer));
//		
//		TestService.upload(fd,function(){
//			
//		})
		
		TestService.post(function(callback){
			
		})
	}
	
	
}