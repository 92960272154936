angular
  .module("app")
  .controller("ConfirmModalController", ConfirmModalController);

ConfirmModalController.$inject = ["config", "$uibModalInstance"];

function ConfirmModalController(config, $modalInstance) {
  var vm = this;

  vm.ok = ok;
  vm.cancel = cancel;
  vm.init = init();

  function init() {
    vm.header = config.header;
    vm.body = config.body;
    vm.remark = config.remark;

    config.yes ? (vm.yes = config.yes) : (vm.yes = "btn.ok");

    config.no ? (vm.no = config.no) : (vm.no = "btn.cancel");

    console.log(vm.no);
  }

  function ok() {
    $modalInstance.close(true);
  }

  function cancel() {
    $modalInstance.dismiss(false);
  }
}
