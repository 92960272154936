angular.module("app").factory('SelectTemplateModalFactory', SelectTemplateModalFactory);

SelectTemplateModalFactory.$inject = ['$uibModal'];

function SelectTemplateModalFactory($uibModal){
    function open(thenCallback) {
        open(thenCallback);
    }

    function open( thenCallback) {
        $uibModal.open({
            templateUrl: 'app/modal/selectTemplate.modal.html',
            controller: 'SelectTemplateModalController',
            controllerAs: 'vm',
            keyboard: false,
            size: "xs"
        }).result.then(function () {
            if (thenCallback) {
                thenCallback();
            }
        }, function(){});
    }

    return {
        open: open
    }
}