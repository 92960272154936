angular.module("app").controller("ViewGroupController", ViewGroupController);

ViewGroupController.$inject = [
  "$log",
  "$translate",
  "$stateParams",
  "ProductService",
  "PagingService",
  "$sessionStorage",
  "$state",
  "ProductGroupService",
];

function ViewGroupController(
  $log,
  $translate,
  $stateParams,
  ProductService,
  PagingService,
  $sessionStorage,
  $state,
  ProductGroupService
) {
  var vm = this;
  var session = $sessionStorage;

  /*UI variable*/
  vm.navs = [];
  vm.searchText;
  vm.products = [];
  vm.totalItems = 0;
  vm.totalPage = 0;
  vm.criteria = {};
  vm.title;
  vm.textSearch = "";

  vm.userLogin = session.user;

  vm.paging = {};

  /*UI function*/
  vm.init = init();
  vm.selectedPage = selectedPage;
  vm.searchText = searchText;

  function init() {
    vm.loadingPage = true;

    vm.groupId = $stateParams.id;
    var email = undefined;

    if (vm.userLogin) {
      email = vm.userLogin.email;
    }

    vm.criteria = {
      page: 1,
      itemPerPage: 18,
      groupId: vm.groupId,
      orde: 1,
    };

    ProductGroupService.get(vm.groupId, function (response) {
      vm.pg = response;
    });

    search();
  }

  function selectedPage(page) {
    vm.criteria.page = page;
    search();
  }

  function searchText() {
    if (vm.textSearch != undefined && vm.textSearch !== "")
      $state.go("app.searchProduct", { text: vm.textSearch });
    vm.textSearch = "";
  }

  function search() {
    vm.loadingItem = true;
    ProductService.getProductsByGroup(vm.criteria, function (response) {
      //Inquiry Product Groups
      vm.products = response.data;
      vm.totalItems = response.totalItems;
      vm.totalPage = response.totalPage;
      vm.title = response.titlePage;

      vm.navs[0] = { description: vm.title };

      PagingService.get(
        vm.criteria.page,
        vm.criteria.itemPerPage,
        vm.totalItems,
        function (response) {
          //Inquiry Product Groups
          vm.paging = response;
          vm.loadingPage = false;
          vm.loadingItem = false;
        }
      );
    });
  }
}
