angular
	.module('app')
	.directive('keyEnter', function() {
	    return function(scope, element, attrs) {
	        element.bind("keydown keypress", function(event) {
	            if (event.which === 13) {
	                scope.$apply(function() {
	                    scope.$eval(attrs.keyEnter);
	                });
	
	                event.preventDefault();
	            }
	        });
	    };
});


angular
	.module('app')
	.directive('convertToNumber', function() {
	  return {
	    require: 'ngModel',
	    link: function(scope, element, attrs, ngModel) {
	      ngModel.$parsers.push(function(val) {
	        return parseInt(val, 10);
	      });
	      ngModel.$formatters.push(function(val) {
	        return '' + val;
	      });
	    }
	  };
});

angular
.module('app')
.directive('fileModel', ['$parse', function ($parse) {
    return {
       restrict: 'A',
       link: function(scope, element, attrs) {
          var model = $parse(attrs.fileModel);
          var modelSetter = model.assign;
          
          element.bind('change', function(){
             scope.$apply(function(){
                modelSetter(scope, element[0].files[0]);
             });
          });
       }
    };
 }]);

angular
.module('app')
.directive('onClickAndHold', function ($parse, $timeout,$interval) {
	  return {
	    link: function (scope, element, attrs) {
	      var clickAndHoldFn = $parse(attrs.onClickAndHold);
	      var doNotTriggerClick;
	      var timeoutHandler;
	      element.on('mousedown', function () {
	    	  $interval.cancel(timeoutHandler);
	          timeoutHandler = $interval(function () { 
	        	  clickAndHoldFn(scope, {$event: event})
	            }, 200);
	      });
	      element.on('mouseup', function (event) {
	    	  $interval.cancel(timeoutHandler);
	      });

	      if (attrs.onClick) {
	          var clickFn = $parse(attrs.onClick);
	          element.on('click', function (event) {
	              if (doNotTriggerClick) {
	                  doNotTriggerClick = false;
	                  return;
	              }
	              clickFn(scope, {$event: event});
	              scope.$apply();
	          });
	      }
	    }
	  }
	})
	
	
angular
.module('app')
.directive('onErrorSrc', function() {
    return {
        link: function(scope, element, attrs) {
          element.bind('error', function() {
            if (attrs.src != attrs.onErrorSrc) {
              attrs.$set('src', attrs.onErrorSrc);
            }
          });
        }
    }
});

angular
.module('app')
.directive('historyBack', ['$window', function($window) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            elem.bind('click', function () {
                $window.history.back();
            });
        }
    };
}]);


angular
.module('app')
.directive('preventRightClick', [  
	  
    function () {  
        return {  
            restrict: 'A',  
            link: function ($scope, $ele) {  
                $ele.bind("contextmenu", function (e) {  
                    e.preventDefault();  
                    alert("Right click is disabled");  
                });  
            }  
        };  
    }  
]) 