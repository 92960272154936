app.service('ConfigService', ConfigService);

ConfigService.$inject = ['$resource', 'CommonCallback', 'config'];

function ConfigService ($resource, CommonCallback, config) {
  var resource = $resource('', {}, {
    'get': {
      method: 'GET',
      isArray: false,
      url: config.apiUrl + '/config'
    }
  });

  function get (successCallBack) {
    resource.get({},
      CommonCallback.onSuccess(successCallBack),
      CommonCallback.onError);
  }

  function getConfig (callback) {
    callback(config);
  }

  return {
    get: get,
    getConfig : getConfig
  };
}
